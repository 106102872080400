import React from 'react'
import { Row, Col } from '@zendeskgarden/react-grid'

import {
  SocialIcon,
  LogoWrapper,
  FooterGrid,
  MiddleCol,
  ContactRow,
  TermsRow,
  LinksRow,
  Copyright,
  Anchor,
} from './Footer.style'

// NOTE: uses fontawesome css (injected by Google Tag Manager - ENABLE_GTM in env.xyz)

const Footer = () => (
  <FooterGrid>
    <Row>
      <Col size={6} sm={4} align="left" className="hide-sm">
        <LogoWrapper>
          <img
            src="/images/agnoStack-icon-inverse.png"
            alt="agnoStack, Inc."
          />
        </LogoWrapper>
      </Col>
      <MiddleCol>
        <Row>
          <Col>
            <SocialIcon>
              <a
                href="https://www.linkedin.com/company/agnostack/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin" />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                href="https://twitter.com/agnostack"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                href="https://www.facebook.com/pg/agnostack/photos/?ref=page_internal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook" />
              </a>
            </SocialIcon>
          </Col>
        </Row>
        <ContactRow>
          <Col>
            <Anchor href="mailto:info@agnostack.com">info@agnostack.com</Anchor>
          </Col>
          <Col>
            <Anchor href="tel:+13126203589">312.620.3589</Anchor>
          </Col>
        </ContactRow>
      </MiddleCol>
      <Col align="right" alignMobile="center" className="hide-sm">
        <Copyright>
          {`© ${new Date().getFullYear()} agnoStack, Inc.`}
        </Copyright>
      </Col>
    </Row>
    <TermsRow className="hide-sm">
      <Col align="center">
        <i>
          <Anchor href="/terms">Terms</Anchor>
        </i>
      </Col>
      <Col align="center">
        <i>
          <Anchor href="/privacy" >Privacy</Anchor>
        </i>
      </Col>
    </TermsRow>
    <LinksRow className="hide-sm">
      <Col>
        <i>
          <Anchor href="/post-purchase-experience-agnoStack">THE Post Purchase Experience Engine</Anchor>
        </i>
      </Col>
      <Col align="center">
        <i>
          <Anchor href="https://stackableapps.com/zendesk" target="_blank">
            <span>Top Zendesk</span>
            <sup>Ⓡ</sup>
            <span> Apps</span>
          </Anchor>
        </i>
      </Col>
      <Col align="center">
        <i>
          <Anchor href="https://commerce.agnostack.com/shopify-zendesk" target="_blank">
            <span>Shopify for Zendesk</span>
            <sup>Ⓡ</sup>
          </Anchor>
        </i>
      </Col>
      <Col align="right">
        <i>
          <Anchor href="https://stackablesupport.com/zendesk-commerce" target="_blank">
            <span>Zendesk</span>
            <sup>Ⓡ</sup>
            <span> eCommerce</span>
          </Anchor>
        </i>
      </Col>
    </LinksRow>
  </FooterGrid>
)

export default Footer
