import { useState, useEffect } from 'react'

import { objectNotEmpty } from '@agnostack/lib-core'

export const useDimensions = (container = window) => {
  const getDimensions = () => {
    const { innerWidth: width, innerHeight: height } = container || {}
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getDimensions(container)
  )

  const resizeDimensions = ({ width, height }) => {
    const resizeWidth = width || (windowDimensions.width ? windowDimensions.width : null)
    const resizeHeight = height || (windowDimensions.height ? windowDimensions.height : null)
    if (resizeWidth && resizeHeight) {
      if (container.resizeTo) {
        container.resizeTo(resizeWidth, resizeHeight)
      }
      if (container.focus) {
        container.focus()
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        const dimensions = getDimensions(container)
        if (objectNotEmpty(dimensions)) {
          setWindowDimensions(dimensions)
        }
      }
    }

    container.addEventListener('resize', handleResize)
    return () => container.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [windowDimensions, resizeDimensions]
}
