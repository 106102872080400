import React, { useRef, useState } from 'react'

import { delay } from '@agnostack/lib-utils-js'

import CodeSnippet, { STATUS } from '../components/atoms/CodeSnippet'

export const useCodeSnippet = ({ children, ...props }) => {
  const inputRef = useRef()
  const buttonRef = useRef()
  const [statusMessage, setStatusMessage] = useState(STATUS.DEFAULT)
  const [copied, setCopied] = useState(false)

  const getClipped = async () => {
    let clipped
    if (navigator && navigator.clipboard) {
      try {
        window.focus()
        clipped = await navigator.clipboard.readText()
      } catch (warn) {
        console.warn('Ignoring error getting clipped', warn)
      }
    }
    return clipped
  }

  const copyToClipboard = async (inputElement, triggerElement) => {
    if (document.execCommand
      && document.queryCommandSupported('copy')
      && inputElement
      && inputElement.value
    ) {
      await delay(200)
      // NOTE: does copy empty inputs, but adds newline before content
      const range = document.createRange()
      range.selectNode(inputElement)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)

      // NOTE: doesn't copy empty inputs
      inputElement.focus()
      inputElement.select()

      // NOTE: doesn't copy empty inputs, but handles other types of inputs
      document.execCommand('selectall')
      document.execCommand('copy')

      if (triggerElement) {
        triggerElement.focus()
      }

      setCopied(true)
      setStatusMessage(STATUS.COPIED)
      await delay(1000)
      inputElement.setSelectionRange(0, 0)
      inputElement.focus()
      setStatusMessage(STATUS.DEFAULT)
      getClipped()
    }
  }

  const renderProps = {
    ...props,
    copied,
    statusMessage,
    onCopy: () => copyToClipboard(inputRef.current, buttonRef.current),
    inputRefCallback: inputRef,
    buttonRefCallback: buttonRef,
  }

  return [
    <CodeSnippet key="code-snippet" {...renderProps}>{children}</CodeSnippet>,
    () => copyToClipboard(inputRef.current, buttonRef.current)
  ]
}
