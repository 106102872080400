import styled from 'styled-components'
import { Tiles } from '@zendeskgarden/react-forms'

export const ListingsContainer = styled(Tiles)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.md}) {
      width: unset;
      display: inline-flex;
    }
  `}
`

export const AppListing = styled(Tiles.Tile)`
  flex-basis: calc(50% - 26px);
  padding: 12px 8px;
  margin: 4px;

  ${({ theme, format = 'light' }) => `
    border-color: ${theme.formats[format].accent};

    @media (min-width: ${theme.breakpoints.sm}) {
      flex-basis: calc(50% - 46px);
      margin: 8px 14px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      flex-basis: unset;
      display: flex;
      flex-direction: column;
      width: 100px;
    }
  `}
`

export const AppIcon = styled.div`
  display: flex;
  justify-content: center;
`

export const IconWrapper = styled(Tiles.Icon)`
  background-color: white;
  padding: 4px;
  border-radius: 4px;
`
