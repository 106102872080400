import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { CountWrapper, Container } from './Counter.style'

const Counter = ({ count, ...renderProps }) => (
  <Container {...renderProps}>
    <TransitionGroup component="span">
      <CSSTransition
        unmountOnExit
        classNames="ctr"
        timeout={{ enter: 300, exit: 300 }}
        key={`counter-${count}`}
      >
        <CountWrapper>{count}</CountWrapper>
      </CSSTransition>
    </TransitionGroup>
  </Container>
)

export default React.memo(Counter)
