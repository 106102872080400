import React from 'react'

import { Emoji } from '@agnostack/lib-utils-react'

import {
  CalloutContainer,
  CalloutWell,
  CalloutWrapper,
} from './Callout.style'

export const CALLOUT_TYPE = {
  ANNOUNCEMENT: 'announcement',
}

const CALLOUTS = {
  [CALLOUT_TYPE.ANNOUNCEMENT]: {
    isFloating: true,
    emoji: '📣',
  },
}

const Callout = ({
  type,
  children,
  ...props
}) => {
  const { emoji, ...calloutProps } = CALLOUTS[type] || {}

  const renderProps = {
    emoji,
    ...calloutProps,
    ...props,
  }

  return (
    <CalloutContainer>
      <CalloutWell {...renderProps}>
        <CalloutWrapper>
          {emoji && (
            <div>
              <Emoji>
                {emoji}
              </Emoji>
            </div>
          )}
          <div>
            {children}
          </div>
          {emoji && (
            <div>
              <Emoji>
                {emoji}
              </Emoji>
            </div>
          )}
        </CalloutWrapper>
      </CalloutWell>
    </CalloutContainer>
  )
}

export default Callout
