export const COMMERCE_PLATFORMS = {
  BIGCOMMERCE: 'BigCommerce',
  COMMERCETOOLS: 'commercetools',
  ELASTIC_PATH: 'ElasticPath',
  MAGENTO_1: 'Magento 1',
  MAGENTO_2: 'Magento 2',
  SALESFORCE: 'Salesforce',
  SHOPIFY: 'Shopify',
  WOOCOMMERCE: 'WooCommerce',
}

export const LISTINGS = {
  AGNOSTACK: 'agnostack',
  BIGCOMMERCE: 'bigcommerce',
  BIGCOMMERCE_DEBUG: 'bigcommerce_debug',
  COMMERCETOOLS: 'commercetools',
  CUSTOMER_INSIGHTS: 'customer_insights',
  MAGENTO_2: 'magento2',
  SHOPIFY: 'shopify',
  SHOPIFY_DEBUG: 'shopify_debug',
  SHOPIFY_PREMIUM: 'shopify_premium',
  SHOPIFY_PREMIUM_DEBUG: 'shopify_premium_debug',
}

export const LISTINGS_DEBUG = [
  LISTINGS.BIGCOMMERCE_DEBUG,
  LISTINGS.SHOPIFY_DEBUG,
  LISTINGS.SHOPIFY_PREMIUM_DEBUG
  // TODO: do we want any other debug listings??
]

export const LISTINGS_UNRESTRICTED = [
  LISTINGS.CUSTOMER_INSIGHTS
]

// TODO: make this a method to see if we remove _debug if in this list???
export const LISTINGS_UNMETERED = [
  // LISTINGS.CUSTOMER_INSIGHTS, // TODO!!!!: bring this back
  LISTINGS.SHOPIFY_PREMIUM
]

// TODO update all env's to use these dynamically instead!!
export const LISTING_APPS = {
  [LISTINGS.AGNOSTACK]: 214713,
  [LISTINGS.BIGCOMMERCE]: 545897,
  [LISTINGS.COMMERCETOOLS]: 835629,
  // [LISTINGS.CUSTOMER_INSIGHTS]: TBD - need app id,
  [LISTINGS.MAGENTO_2]: 952955,
  // [LISTINGS.SHOPIFY]: NEXT_PUBLIC_URL_ZENDESK_SHOPIFY_LISTING,
  [LISTINGS.SHOPIFY_PREMIUM]: 867416,
}

export const LISTING_PLATFORMS = {
  [LISTINGS.BIGCOMMERCE]: COMMERCE_PLATFORMS.BIGCOMMERCE,
  [LISTINGS.COMMERCETOOLS]: COMMERCE_PLATFORMS.COMMERCETOOLS,
  [LISTINGS.MAGENTO_2]: COMMERCE_PLATFORMS.MAGENTO_2,
  [LISTINGS.SHOPIFY]: COMMERCE_PLATFORMS.SHOPIFY,
  [LISTINGS.SHOPIFY_PREMIUM]: COMMERCE_PLATFORMS.SHOPIFY,
}

// NOTE: these allow us to force showing the first step
// TODO!!!: fix this to not be hardcoded to check LISTINGS_FORCE_PROVIDERS
export const LISTINGS_FORCE_PROVIDERS = Object.values(LISTINGS).filter((listing) => (
  listing !== LISTINGS.AGNOSTACK
))
