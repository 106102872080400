import styled from 'styled-components'

export const Footer = styled.div`
  padding-top: 1em;
  text-align: center;
`

export const Tagline = styled.div`
  margin-top: 2em;
`
