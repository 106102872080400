import React, { useRef, useEffect } from 'react'
import TypewriterCore from 'typewriter-effect/dist/core'

import { ensureArray } from '@agnostack/lib-core'
import { delay } from '@agnostack/lib-utils-js'

const typeStrings = async (typewriter, { strings, duration }) => (
  ensureArray(strings).reduce(async (previousTypewriterPromise, string, index) => {
    const previousTypewriter = await previousTypewriterPromise
    const typed = await previousTypewriter.typeString(string)

    return index === strings.length - 1
      ? typed
        .changeCursor(' ')
      : typed
        .pauseFor(duration * 2)
        .deleteAll()
        .pauseFor(duration)
  }, Promise.resolve(typewriter))
)

const Typewriter = ({ options, children, strings = [], duration = 750 }) => {
  const typewriterRef = useRef()
  const defaultString = children || strings[strings.length - 1]

  useEffect(() => {
    if (strings.length > 1) {
      const asyncMethod = async () => {
        await delay(duration * 4)
        if (typewriterRef && typewriterRef.current) {
          const typewriter = new TypewriterCore(typewriterRef.current, {
            strings,
            ...options,
          });
          (await typeStrings(typewriter, { strings, duration }))
            .start()
        }
      }

      asyncMethod()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={typewriterRef}>
      {defaultString}
    </div>
  )
}

export default Typewriter
