import {
  uppercase,
  ensureString,
  ensureObject,
  objectNotEmpty,
  objectContainsAnyValue,
  stringNotEmpty,
  arrayNotEmpty,
  ensureArray,
  VALIDATION_STATUS,
} from '@agnostack/lib-core'

const VALIDATE_SETTING = /^{{setting\.[a-zA-Z0-9_-]+}}$/
// TODO!!!: update these to support setting.<XYZ>.token ??
const VALIDATE_SETTING_TOKEN = /^{{setting\.token[a-zA-Z0-9_-]*}}$/
const VALIDATE_SETTING_TOKENIZED = /^{{setting\.token_[a-zA-Z0-9_-]+}}$/
const VALIDATE_NOT_INVALID = /^((?!(undefined|null)).)+$/i
const VALIDATE_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
const VALIDATE_URL_QUALIFIED = /^http(s)?:\/\/[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
const VALIDATE_URL_QUALIFIED_SECURE = /^https:\/\/[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
const VALIDATE_URL_QUALIFIED_OR_ALPHANUMERIC_SEPARATOR = /^http(s)?:\/\/[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+|^[a-zA-Z0-9_-]+$/
const VALIDATE_NAME = /^[a-z]+([a-z',. -]?[a-z.]+)*$/i
const VALIDATE_EMAIL = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+[.]+(?:.[a-z0-9-]+)+$/i
const VALIDATE_ZIP = /^\b\d{5}(-\d{4})?\b$/
const VALIDATE_ALPHA = /^[a-z]+$/i
const VALIDATE_ALPHANUMERIC = /^[a-z0-9]+$/i
const VALIDATE_ALPHANUMERIC_UNDERSCORE = /^[a-z0-9_]+$/i
const VALIDATE_ALPHANUMERIC_UNDERSCORE_LIST = /^(([a-z0-9_-](,\s?)?))+$/i
const VALIDATE_ALPHANUMERIC_SEPARATOR = /^[a-z0-9_-]+$/i
const VALIDATE_ALPHANUMERIC_SEPARATOR_DOT = /^[a-z0-9._-]+$/i
const VALIDATE_ALPHANUMERIC_EXTRA = /^(([a-z0-9_-|](,\s?)?)+|\*)$/i // NOTE: (alpha separator plus ',' or ', 'or '|') OR '*'
const VALIDATE_ALPHANUMERIC_MAX = /^(([a-z0-9.~@+|/_-](,\s?)?)+|\*)$/i // NOTE: (alpha separator dot plus ',' or ', ' or '@', or '+', or '/'or '|') OR '*'
const VALIDATE_SHARED_SECRET = /^(([a-z0-9.~@+|=/_-](,\s?)?)+|\*)$/i // NOTE: (alpha max plus '=')
// eslint-disable-next-line max-len
const VALIDATE_ARN = /^arn:(?<Partition>[^:\n]*):(?<Service>[^:\n]*):(?<Region>[^:\n]*):(?<AccountID>[^:\n]*):(?<Ignore>(?<ResourceType>[^:/\n]*)[:/])?(?<Resource>.*)$/i
const VALIDATE_NUMERIC = /^[0-9]+$/
const VALIDATE_NUMERIC_EXTRA = /^(([0-9](,\s?)?)+|\*)$/i // NOTE: (numeric plus ',' or ', ') OR '*'
const VALIDATE_NUMERIC_EXTRA_OR_ALL = /^(([0-9](,\s?)?)+|\*|ALL)$/i // NOTE: (numeric plus ',' or ', ') OR '*' OR 'ALL'
const VALIDATE_WILDCARD = /^(\*|ANY)$/i // NOTE: '*' OR 'ANY'
const VALIDATE_TOKEN = /^[a-z0-9_./~=+-]+$/i
const VALIDATE_TOKEN_32 = /^[a-z0-9_./~=+-]{32}$/i
const VALIDATE_TOKEN_64 = /^[a-z0-9_./~=+-]{64}$/i
const VALIDATE_TOKEN_32_64 = /(^[a-z0-9_./~=+-]{32}$|^[a-z0-9_./~=+-]{64}$)/i
const VALIDATE_FORMATTED_TIMESTAMP = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
const VALIDATE_UNIX_TIMESTAMP = /^\d{1,10}$/
const VALIDATE_ROUNDED_PRICE = /^\D{1,3}\d{1,3}(,\d{3})*$/
const VALIDATE_FORMATTED_PRICE = /^\D{1,3}\d{1,3}(,\d{3})*(\.\d{2})$/

export const validators = {
  VALIDATE_SETTING,
  VALIDATE_SETTING_TOKEN,
  VALIDATE_SETTING_TOKENIZED,
  VALIDATE_NOT_INVALID,
  VALIDATE_URL,
  VALIDATE_URL_QUALIFIED, // FULLY QUALIFIED URL
  VALIDATE_URL_QUALIFIED_SECURE,
  VALIDATE_NAME,
  VALIDATE_EMAIL,
  VALIDATE_ZIP,
  VALIDATE_ALPHA,
  VALIDATE_ALPHANUMERIC,
  VALIDATE_ALPHANUMERIC_UNDERSCORE,
  VALIDATE_ALPHANUMERIC_UNDERSCORE_LIST,
  VALIDATE_ALPHANUMERIC_SEPARATOR_DOT,
  VALIDATE_ALPHANUMERIC_SEPARATOR,
  VALIDATE_ALPHANUMERIC_EXTRA,
  VALIDATE_ALPHANUMERIC_MAX,
  VALIDATE_SHARED_SECRET,
  VALIDATE_ARN,
  VALIDATE_NUMERIC,
  VALIDATE_NUMERIC_EXTRA,
  VALIDATE_NUMERIC_EXTRA_OR_ALL,
  VALIDATE_WILDCARD,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_32,
  VALIDATE_TOKEN_64,
  VALIDATE_TOKEN_32_64,
  VALIDATE_FORMATTED_TIMESTAMP,
  VALIDATE_UNIX_TIMESTAMP,
  VALIDATE_ROUNDED_PRICE,
  VALIDATE_FORMATTED_PRICE,
  VALIDATE_URL_QUALIFIED_OR_ALPHANUMERIC_SEPARATOR,
}

export const isValidEmail = (input) => (
  stringNotEmpty(input) && validators.VALIDATE_EMAIL.test(input)
)

export const isValidRegex = (input, regex) => (
  stringNotEmpty(input) && regex && regex?.test?.(input)
)

export const isValidName = (input) => (
  stringNotEmpty(input) && validators.VALIDATE_NAME.test(input)
)

export const isValidZip = (zip, country = 'US') => (
  (country === 'US')
    ? arrayNotEmpty(ensureString(zip).match(validators.VALIDATE_ZIP)) // HMMM: should this do a test instead
    : stringNotEmpty(zip) // TODO: lookup validation regex by country
)

export const validateRegex = (
  input,
  regexes,
  errorMessage
) => {
  const isValid = regexes && ensureArray(regexes).some((regex) => (
    isValidRegex(input, regex)
  ))

  return {
    isValid,
    validationStatus: isValid ? VALIDATION_STATUS.SUCCESS : VALIDATION_STATUS.ERROR,
    errorMessage,
    isRegex: true,
  }
}

export const validateEmail = (
  email,
  errorMessage
) => {
  const isValid = isValidEmail(email)

  return {
    isValid,
    validationStatus: isValid ? VALIDATION_STATUS.SUCCESS : VALIDATION_STATUS.ERROR,
    errorMessage,
  }
}

export const validateName = (
  name,
  errorMessage
) => {
  const isValid = isValidName(name)

  return {
    isValid,
    validationStatus: isValid ? VALIDATION_STATUS.SUCCESS : VALIDATION_STATUS.ERROR,
    errorMessage,
  }
}

export const validateZip = (
  zip,
  country,
  errorMessage
) => {
  const isValid = isValidZip(zip, country)

  return {
    isValid,
    validationStatus: isValid ? VALIDATION_STATUS.SUCCESS : VALIDATION_STATUS.ERROR,
    errorMessage,
  }
}

export const validateAddress = ({
  line_1,
  city,
  region,
  postcode,
  country,
} = {}) => (
  stringNotEmpty(line_1) &&
  stringNotEmpty(city) &&
  stringNotEmpty(region) &&
  stringNotEmpty(postcode) &&
  stringNotEmpty(country)
)

export const validateNotEmpty = (
  value,
  errorMessage
) => {
  // eslint-disable-next-line eqeqeq
  const isValid = ((value != undefined) && (value !== '')) // TODO: move to stringNotEmpty??

  return {
    isValid,
    validationStatus: isValid ? VALIDATION_STATUS.SUCCESS : VALIDATION_STATUS.ERROR,
    errorMessage,
  }
}

export const validateAlways = (
  value,
  errorMessage = ''
) => ({
  isValid: true,
  validationStatus: VALIDATION_STATUS.SUCCESS,
  errorMessage,
})

export const isValidUnparsedAddress = (address) => {
  const { type, unparsed } = ensureObject(address)

  return ((type === 'address') && stringNotEmpty(unparsed))
}

const isValidParsedAddress = (address) => (
  objectContainsAnyValue(address)
)

export const isValidAddress = (addressData) => {
  const { id, type, first_name, last_name, ...address } = ensureObject(addressData)

  return objectNotEmpty(address) && (isValidUnparsedAddress(address) || isValidParsedAddress(address))
}

export const getValidInitials = (_strings) => {
  const strings = ensureArray(_strings)

  let initials = ''
  // NOTE: intentionally using for/of loop instead of map so we can break to exit quickly soon as matched
  // eslint-disable-next-line no-restricted-syntax
  for (const string of strings) {
    if (stringNotEmpty(string)) {
      if (isValidEmail(string)) {
        initials = `${string.charAt(0)}@`
        break
      }

      const [first, second] = ensureArray(
        string
        .replace(/[^a-zA-Z- ]/g, '')
        .match(/\b\w/g)
      )

      initials = [first, second].join('')
      break
    }
  }

  return uppercase(initials)
}
