import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import { ensureString } from '@agnostack/lib-core'

export const editable = (EditingComponent, outerProps = {}) => {
  const StyledEditingComponent = styled(EditingComponent)`
    margin-left: -1px;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
    ${({ editing, theme: { colors, palette } = {} }) => `
      ${editing ? `
        background: ${palette.white};
        border-color: ${palette[colors.primaryHue][600]}!important;
        box-shadow: 0 0 0 3px rgba(31, 115, 183, 0.35) !important;
        outline: thick none !important;
      ` : ''}
      &:hover {
        border-color: ${palette[colors.neutralHue][300]};
        background: ${palette[colors.neutralHue][100]};
      }
    `}
  `

  const EditableHOC = (innerProps = {}) => {
    const domElm = useRef(null)
    const props = { ...innerProps, ...outerProps }
    const {
      children,
      editedCallback,
      editing: editingProp,
      editable: isEditable,
      ...componentProps
    } = props
    const [editing, setEditing] = useState(editingProp)
    const original = children
    const _editable = isEditable || false

    const handleEditedCallback = async (callback, arg) => callback(arg)

    useEffect(() => {
      if (editedCallback && _editable && domElm?.current) {
        if (editing) {
          domElm.current.focus()
          try {
            document.execCommand('selectAll', false, null)
          // eslint-disable-next-line no-empty
          } catch (_ignore) {}
        } else if (
          // eslint-disable-next-line eqeqeq
          children != undefined &&
          ensureString(children) !== ensureString(domElm.current.textContent)
        ) {
          handleEditedCallback(editedCallback, domElm.current.textContent)
            .catch((warn) => {
              console.warn('Ignoring error editedCallback', warn)
              domElm.current.textContent = original
            })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing, domElm?.current])

    const edit = () => {
      setEditing(true)
    }

    const save = () => {
      setEditing(false)
    }

    const cancel = () => {
      if (domElm?.current) {
        domElm.current.textContent = original
      }
      setEditing(false)
    }

    const handleBlur = () => {
      save()
    }

    const handleKeyDown = (e) => {
      const { key } = e
      switch (key) {
        case 'Enter':
          save()
          break
        case 'Escape':
          cancel()
          break
        default:
          break
      }
    }

    const handleClick = (e) => {
      if (e?.stopPropagation) {
        e.stopPropagation()
      }

      if (!editing) {
        edit()
      }
    }

    return _editable ? (
      <StyledEditingComponent
        ref={domElm}
        contentEditable={editing}
        editing={editing}
        editable={editable}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onClick={handleClick}
        {...componentProps}
      >
        {children}
      </StyledEditingComponent>
    ) : (
      <EditingComponent {...componentProps}>{children}</EditingComponent>
    )
  }

  return EditableHOC
}
