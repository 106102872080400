import React, { useMemo, useState, useEffect, useReducer } from 'react'

import { Col } from '@zendeskgarden/react-grid'
import { Stepper } from '@zendeskgarden/react-accordions'

import {
  ensureNumeric,
  ensureObject,
  ensureArray,
  objectEmpty,
  arrayNotEmpty,
  stringNotEmpty,
} from '@agnostack/lib-core'
import { numeric } from '@agnostack/lib-utils-js'
import { useSafeEffect } from '@agnostack/lib-utils-react'

import {
  CalculatorWrapper,
  CalculatorControls,
  CalculatorTotals,
  TitleRow,
  StepperRow,
  AgentsRow,
  TicketsRow,
  FootnoteRow,
  StepperStep,
  StepperLabel,
  AgentsLabel,
  AgentsLabelFigure,
  AgentsLabelSub,
  AgentsRange,
  StyledLabel,
  StyledNumeric,
  TotalsWrapper,
  TotalBox,
  TotalAmount,
  TotalLabel,
  TotalSubAmount,
  Punctuation,
} from './SavingsCalculator.style'

const DEFAULT_AGENT_TICKETS = 600
const DEFAULT_AGENT_RATE = 10

const SavingsCalculator = ({
  tiers,
  selectedTier: selectedTierProp,
  onSelect = () => {},
  onExpand = () => {},
  ...renderProps
}) => {
  const [agents, setAgents] = useReducer((_agents, updatedAgents) => (
    ensureNumeric(updatedAgents ?? _agents)
  ), 1)
  const [selectedTier, setSelectedTier] = useState()
  const [monthlyTicketsPerAgent, setMonthlyTicketsPerAgent] = useState(DEFAULT_AGENT_TICKETS)
  const [agentHourlyRate, setAgentHourlyRate] = useState(DEFAULT_AGENT_RATE)
  const [configuring, setConfiguring] = useState(false)

  useEffect(() => {
    if (objectEmpty(selectedTier) && arrayNotEmpty(tiers)) {
      const defaultTier = tiers[0]
      setSelectedTier(defaultTier)
      setAgents(defaultTier?.minimum_agents)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiers])

  useSafeEffect(() => {
    if (stringNotEmpty(selectedTierProp?.plan)) {
      setSelectedTier(selectedTierProp)
      // eslint-disable-next-line eqeqeq
      if (selectedTierProp?.minimum_agents && (ensureNumeric(selectedTierProp?.minimum_agents) > agents)) {
        setAgents(selectedTierProp.minimum_agents)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTierProp])

  useSafeEffect(() => {
    onSelect(selectedTier)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier])

  const {
    selectedTierIndex,
    plan: selectedPlanName,
    seconds_saved_per_ticket: secondsSavedPerTicket,
    revenue_generated_per_ticket: revenuePerTicket = 0,
    minimum_agents: minimumMonthlyAgents,
    price_per_agent: monthlyPricePerAgent,
  } = useMemo(() => {
    const {
      plan,
      // eslint-disable-next-line camelcase
      minimum_agents,
    } = ensureObject(selectedTier)
    if (minimum_agents && (ensureNumeric(minimum_agents) > agents)) {
      setAgents(minimum_agents)
    }

    return {
      selectedTierIndex: ensureArray(tiers).findIndex(({ plan: planName }) => planName === plan),
      ...selectedTier,
    }
  }, [agents, tiers, selectedTier])

  const {
    monthlyAgentHours,
    monthlyRevenue,
    monthlySavings,
    monthlyCost,
    ROI,
  } = useMemo(() => {
    const updatedMonthlyAgentHours = (agents * monthlyTicketsPerAgent * secondsSavedPerTicket) / 60 / 60
    const updatedMonthlyRevenue = monthlyTicketsPerAgent * revenuePerTicket
    const updatedMonthlySavings = updatedMonthlyAgentHours * agentHourlyRate
    const updatedMonthlyCost = Math.max(agents, ensureNumeric(minimumMonthlyAgents ?? 1)) * monthlyPricePerAgent
    const updatedROI = Math.max((((updatedMonthlyRevenue + updatedMonthlySavings) - updatedMonthlyCost) / updatedMonthlyCost), 0).toFixed(1)

    return {
      monthlyAgentHours: Math.floor(updatedMonthlyAgentHours),
      monthlyRevenue: updatedMonthlyRevenue,
      monthlySavings: updatedMonthlySavings,
      monthlyCost: updatedMonthlyCost,
      ROI: updatedROI,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents, minimumMonthlyAgents, monthlyTicketsPerAgent, secondsSavedPerTicket, agentHourlyRate])

  const savingsDetails = secondsSavedPerTicket >= 60
    ? `${secondsSavedPerTicket / 60} minutes`
    : `${secondsSavedPerTicket} seconds`

  const calculatedMonthlySavings = monthlySavings + monthlyRevenue

  return (
    <CalculatorWrapper {...renderProps}>
      <CalculatorControls>
        <TitleRow>
          <Col>
            <h2>Monthly Savings Calculator</h2>
          </Col>
        </TitleRow>
        <StepperRow>
          <Col>
            <Stepper isHorizontal activeIndex={selectedTierIndex}>
              {ensureArray(tiers).map((tier, tierIndex) => {
                const { plan } = tier
                const isActive = selectedTierIndex === tierIndex
                return (
                  <StepperStep key={plan} isActive={isActive}>
                    <StepperLabel
                      icon={<div />}
                      isActive={isActive}
                      onClick={() => {
                        setSelectedTier(tier)
                      }}
                    >
                      {plan}
                    </StepperLabel>
                  </StepperStep>
                )
              })}
            </Stepper>
          </Col>
        </StepperRow>
        <AgentsRow>
          <AgentsLabel>
            <AgentsLabelFigure>
              {agents}
            </AgentsLabelFigure>
            <AgentsLabelSub>{`${agents === '1' ? 'Agent' : 'Agents'}`}</AgentsLabelSub>
          </AgentsLabel>
          <AgentsRange
            value={agents}
            min={1}
            max={50}
            onChange={(event) => {
              setAgents(event.target.value)
            }}
          />
        </AgentsRow>
        {configuring && (
          <TicketsRow>
            <Col sm={6}>
              <StyledNumeric
                value={monthlyTicketsPerAgent}
                min={1}
                max={10000}
                step={200}
                onChange={setMonthlyTicketsPerAgent}
              />
              <StyledLabel>Tickets (per agent/mo)</StyledLabel>
            </Col>
            <Col sm={6}>
              <StyledNumeric
                value={agentHourlyRate}
                onChange={setAgentHourlyRate}
              />
              <StyledLabel>Agent Hourly Rate ($)</StyledLabel>
            </Col>
          </TicketsRow>
        )}
        <FootnoteRow>
          <Col>
            <sup>* </sup>
            {`Using agnoStack, customers on our ${selectedPlanName} Plan, typically save ${savingsDetails} per ticket each month. `}
            <a
              href="/#"
              title={`${configuring ? 'Hide Details' : 'Show and Configure Details'}`}
              onClick={(e) => {
                e.preventDefault()
                onExpand(!configuring)
                setConfiguring(!configuring)
              }}
            >
              {`${configuring ? 'Hide Details' : 'Show and Configure Details'}`}
            </a>
          </Col>
        </FootnoteRow>
      </CalculatorControls>
      <CalculatorTotals>
        <TotalsWrapper>
          <TotalBox>
            <TotalAmount>
              {monthlyCost ? numeric(monthlyCost, {
                formatWithSymbol: true,
              }).trim() : (<>&nbsp;</>)}
            </TotalAmount>
            <TotalLabel>Monthly cost</TotalLabel>
          </TotalBox>
          <TotalBox>
            <TotalAmount>
              {calculatedMonthlySavings ? (
                <>
                  <Punctuation>(</Punctuation>
                  <span>
                    {numeric(calculatedMonthlySavings, {
                      formatWithSymbol: true,
                    }).trim()}
                  </span>
                  <Punctuation>)</Punctuation>
                </>
              ) : (<>&nbsp;</>)}
            </TotalAmount>
            <TotalLabel>Monthly savings</TotalLabel>
          </TotalBox>
          <TotalBox>
            <TotalAmount>
              {monthlyAgentHours}
              <TotalSubAmount>hrs</TotalSubAmount>
            </TotalAmount>
            <TotalLabel>Agent hours saved</TotalLabel>
          </TotalBox>
          <TotalBox>
            <TotalAmount>
              {ROI}
              x
            </TotalAmount>
            <TotalLabel>ROI</TotalLabel>
          </TotalBox>
        </TotalsWrapper>
      </CalculatorTotals>
    </CalculatorWrapper>
  )
}

export default SavingsCalculator
