import React, { useState, useRef, useEffect } from 'react'
import { withTheme } from 'styled-components'

import { Field } from '@zendeskgarden/react-forms'

import { delay, validators, validateRegex } from '@agnostack/lib-utils-js'

import {
  InputWrapper,
  DefaultInput,
  TextInput,
} from './SubdomainInput.style'

const validateInput = (
  value,
  errorMessage = 'Please enter your Zendesk domain'
) => (
  validateRegex(value, validators.VALIDATE_ALPHANUMERIC_SEPARATOR, errorMessage)
)

const SubdomainInput = ({
  subdomain,
  onSubmit = () => {},
}) => {
  const textInputRef = useRef()
  const [inputValue, setInputValue] = useState(subdomain)

  const setFocus = async (ref) => {
    await delay(50)
    if (ref && ref.current) {
      ref.current.focus()
    }
  }

  useEffect(() => {
    if (validateInput(inputValue).isValid) {
      onSubmit(`${inputValue}.zendesk.com`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <Field>
      <InputWrapper
        tabIndex={0}
        onFocus={() => setFocus(textInputRef)}
        mediaLayout
      >
        <span>
          <DefaultInput>https://</DefaultInput>
          <TextInput
            autoFocus
            placeholder="<<subdomain>>"
            value={inputValue}
            ref={textInputRef}
            // TODO: handle removal of special characters
            // ensureString(value).replace(/^[a-z0-9_-]+$/gi
            onChange={({ target: { value } }) => setInputValue(value)}
          />
          <DefaultInput>.zendesk.com</DefaultInput>
        </span>
      </InputWrapper>
    </Field>
  )
}

export default withTheme(SubdomainInput)
