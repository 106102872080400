import ReactDOM from 'react-dom'
import React, { cloneElement } from 'react'
import { document } from 'browser-monads-ts'

import {
  lowercase,
  ensureString,
  ensureObject,
  normalizeArray,
} from '@agnostack/lib-core'

export const createPortal = (children = null, container, key) => (
  ReactDOM.createPortal(children, container, key)
)

export const createElement = (Component, props, children = null) => (
  React.createElement(Component, props, children)
)

export const fromHTMLElement = (element, { type, ...props } = {}) => {
  const elementAttibutes = [...element.attributes].reduce((_elementAttibutes, { name, value }) => ({
    ..._elementAttibutes,
    [name]: value,
  }), ensureObject(props))

  return createElement(
    type ?? lowercase(element.tagName),
    elementAttibutes
  )
}

const findRootContainer = (classNames) => {
  let container
  // NOTE: intentionally using for/of for early break for performance!
  // eslint-disable-next-line no-restricted-syntax
  for (const className of normalizeArray(classNames)) {
    container = document?.getElementsByClassName?.(className)?.[0]
    if (container) {
      break
    }
  }

  return container
}

export const renderComponent = (Component, props = {}, children = null, className = 'react-app') => {
  const classNames = ensureString(props.class ?? props.className ?? props.classNames ?? className).split(' ')
  const container = findRootContainer(classNames)

  if (container) {
    ReactDOM.render(
      createElement(Component, {
        ...container.dataset,
        ...props,
      }, children),
      container
    )
  }
}

// TODO: replace w/ following when moving to React 18??
// import { cloneElement, createElement } from 'react'
// import { createRoot, hydrateRoot } from 'react-dom/client'

// export const renderComponent = (Component, props = {}, children = null, className = 'react-app') => {
//   const classNames = ensureString(props.class ?? props.className ?? props.classNames ?? className).split(' ')
//   const container = findRootContainer(classNames)
//
//   if (container?.hasChildNodes?.()) {
//     hydrateRoot(
//       container,
//       createElement(Component, props, children)
//     )
//   } else {
//     const root = createRoot(container)
//     root?.render(
//       createElement(Component, props, children)
//     )
//   }
// }

export const convertElement = (component, props) => {
  // NOTE: these props are removed before renderring markup output
  const {
    header,
    bare,
    sticky,
    format,
    mode,
    maxWide,
    offset,
    offsets,
    relative, // TODO/HMM: investigate what relative prop here is doing
    ...renderProps
  } = props
  return cloneElement(component, renderProps)
}
