import React, { Fragment, useRef, useEffect } from 'react'

import { createPortal } from '../../render'

const ReactPortal = ({
  children,
  portalKey,
  portalContainer: _portalContainer,
  component: Component = Fragment,
  ...props
}) => {
  const portalContainer = useRef()

  useEffect(() => {
    if (!portalContainer?.current && _portalContainer) {
      portalContainer.current = _portalContainer
      // eslint-disable-next-line no-param-reassign
      _portalContainer.innerHTML = ''
    }
  }, [portalContainer?.current])

  if (!portalContainer?.current || !_portalContainer) {
    return null
  }

  return createPortal(
    <Component {...props}>
      {children}
    </Component>,
    portalContainer.current,
    portalKey
  )
}

export default ReactPortal
