import styled from 'styled-components'
import { Tag } from '@zendeskgarden/react-tags'

export const ButtonWrapper = styled(Tag).attrs(() => ({
  isPill: true,
  size: 'large',
  role: 'button',
}))`
  height: unset;
  justify-content: center;
  margin-bottom: 3px;

  & > * {
    overflow: unset;
    width: unset;
    flex-grow: 1;
  }
`

export const WrapperBody = styled.span`
  text-align: center;
  text-transform: uppercase;
`

export const ShareButtonsContainer = styled.span`
  max-height: 20px;
  padding-left: 8px;
  
  > button {
    outline: none;

    &:not(:last-child) {
      margin-right: 8px;
    }

    svg {
      transition: border .4s ease;
      border: 1px solid transparent;
      border-radius: 50%;
    }
  }

  > button:hover svg {
    ${({ theme }) => `
      border-color: ${theme.colors.shadow1};
    `}
  }
`
