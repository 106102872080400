import React, { useRef } from 'react'

import { delay } from '@agnostack/lib-utils-js'
import { useAsyncMemo } from '@agnostack/lib-utils-react'

import { Container, ContentWrapper } from '../../../styles/core'

import { Backdrop } from './Section.style'

const SectionWrapper = ({ id, mode, children, 'data-expanded': expanded, ...renderProps }) => {
  const wrapperRef = useRef()

  const sectionHeight = useAsyncMemo(async () => {
    // NOTE: intentional delay to ensure rendered
    await delay(10)
    return wrapperRef?.current?.children?.[0]?.clientHeight ?? 0
  }, [wrapperRef?.current, expanded], 0)

  if (mode !== 'overlay') {
    return (
      <Container id={id} mode={mode} {...renderProps}>
        {children}
      </Container>
    )
  }

  return (
    <section id={id}>
      <div ref={wrapperRef}>
        <Container {...renderProps} mode={mode} component={<div />}>
          {children}
        </Container>
      </div>
      <Backdrop {...renderProps} sectionHeight={sectionHeight} />
    </section>
  )
}

const Section = ({ children, wide, mode, ...renderProps }) => (
  <SectionWrapper {...renderProps} mode={mode}>
    <ContentWrapper wide={wide} maxWide={mode === 'overlay'}>
      {children}
    </ContentWrapper>
  </SectionWrapper>
)

export default Section
