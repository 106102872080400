import React from 'react'

import { OverlayWrapper, OverlayContainer } from './Overlay.style'

const Overlay = ({ children, className, ...renderProps }) => (
  <OverlayWrapper className={className}>
    <OverlayContainer {...renderProps}>
      {children}
    </OverlayContainer>
  </OverlayWrapper>
)

export default Overlay
