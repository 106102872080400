import React from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share'

import { ensureArray, arrayNotEmpty } from '@agnostack/lib-core'

import { TITLE_SHARE } from '../../../util'
import {
  ButtonWrapper,
  WrapperBody,
  ShareButtonsContainer,
} from './ShareButton.style'

const ShareButton = ({
  url,
  subject,
  hashtags,
  shareType = 'link',
  children = TITLE_SHARE,
}) => (
  <ButtonWrapper>
    <WrapperBody>
      {children}
    </WrapperBody>
    <ShareButtonsContainer>
      <FacebookShareButton
        url={url}
        quote={subject}
        hashtag={hashtags && `#${ensureArray(hashtags)[0]}`}
      >
        <FacebookIcon round size={18} />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={subject}
        hashtags={hashtags}
      >
        <TwitterIcon round size={18} />
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        title={subject}
        summary={arrayNotEmpty(hashtags) ? hashtags.map((hashtag) => `#${hashtag}`).join(' ') : ''}
      >
        <LinkedinIcon round size={18} />
      </LinkedinShareButton>
      <EmailShareButton
        url={url}
        subject={subject}
        body={`Check out this ${shareType}!${hashtags && `\n\n${arrayNotEmpty(hashtags) ? hashtags.map((hashtag) => `#${hashtag}`).join(' ') : ''}`}`}
        separator={'\n\n'}
      >
        <EmailIcon round size={18} />
      </EmailShareButton>
    </ShareButtonsContainer>
  </ButtonWrapper>
)

export default ShareButton
