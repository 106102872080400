import React from 'react'
import { navigate } from 'gatsby'
import { window } from 'browser-monads-ts'

import { Button } from '@zendeskgarden/react-buttons'

import { stringNotEmpty } from '@agnostack/lib-core'

const LinkButton = ({ href, target, children, onClick = () => {}, ...renderProps }) => {
  const handleClick = (event) => {
    if (stringNotEmpty(href)) {
      if (target) {
        window.open(href, target)
      } else {
        navigate(href)
      }
    }
    onClick(event)
  }

  return (
    <Button onClick={handleClick} {...renderProps}>
      {children}
    </Button>
  )
}

export default LinkButton
