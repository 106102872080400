import React, { useMemo } from 'react'
import { window } from 'browser-monads-ts'
import { withTheme } from 'styled-components'

import { browseable } from '@agnostack/lib-utils-react'
import { splitCommas, stringNotEmpty } from '@agnostack/lib-core'

import { useDimensions } from '../../../hooks'
import { VimeoPlayer, YouTubePlayer } from './VideoFrame.style'

// TODO: consolidate w/ duplicate VideoFrame.js
const VideoFrame = browseable(({ theme, vimeoId, youTubeId, loop, list, width: _width, ...props }) => {
  const [{ width: pageWidth }] = useDimensions(window)
  const { breakpoints } = theme || {}
  const { md: breakpointPx = '', numeric } = breakpoints || {}
  const breakpoint = numeric(breakpointPx)
  const width = _width || Math.min(((pageWidth >= breakpoint) ? pageWidth * 0.5 : pageWidth * 0.75), 1280)
  const height = width * (9 / 16)

  const { Player, ...renderProps } = useMemo(() => {
    let playerProps = {}

    switch (true) {
      case stringNotEmpty(vimeoId): {
        const params = {
          autoplay: 1,
          ...loop && {
            loop: 1,
          },
        }
        playerProps = {
          src: `https://player.vimeo.com/video/${vimeoId}?${Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')}`,
          Player: VimeoPlayer,
        }
        break
      }

      case stringNotEmpty(youTubeId): {
        const youTubeIds = splitCommas(youTubeId)
        const params = {
          autoplay: 1,
          ...list && {
            listType: 'playlist',
            list,
          },
          ...loop && {
            loop: 1,
            ...!list && {
              playlist: youTubeIds.join(','),
            },
          },
        }

        playerProps = {
          src: `https://www.youtube.com/embed/${youTubeIds[0]}?${Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')}`,
          Player: YouTubePlayer,
        }
        break
      }

      default:
    }

    return {
      ...props,
      width,
      height,
      ...playerProps,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vimeoId, youTubeId])

  return Player ? (
    <Player {...renderProps} />
  ) : null
})

export default withTheme(VideoFrame)
