import styled from 'styled-components'

import { Emoji } from '@agnostack/lib-utils-react'

import { shake } from '../../../styles/core'
import { PillButton } from '../styled'

export const BellEmoji = styled(Emoji)`
  flex-grow: 0;
  padding: unset;
  min-width: unset;
  padding-right: 8px;
`

export const StyledButton = styled(PillButton)`
  &:hover > ${BellEmoji} {
    animation: ${shake} 0.9s cubic-bezier(.35,.07,.2,.95) both;
  }
`

export const ButtonWrapper = styled.span`
  text-align: unset;
  text-transform: uppercase;
  padding-right: 6px;
`
