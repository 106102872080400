import styled from 'styled-components'
import NumericInput from 'react-numeric-input'

import { Row } from '@zendeskgarden/react-grid'
import { Stepper } from '@zendeskgarden/react-accordions'
import { Label, Range } from '@zendeskgarden/react-forms'

import { ensureNumeric } from '@agnostack/lib-core'

export const Punctuation = styled.span`
  font-size: .85em;
`

export const CalculatorWrapper = styled.div`
  border-radius: 0;
  display: flex;
  flex-direction: column;
  ${({ theme, format }) => `
    ${format ? `
      background-color: ${theme.formats[format].background};
      color: ${theme.formats[format].color};
    ` : ''}
    @media (min-width: ${theme.breakpoints.md}) {
      margin: 0 1.5rem;
      border-radius: 15px 15px 0 0;
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      flex-direction: row;
    }
  `};
`

export const CalculatorControls = styled.div`
  flex-basis: 65%;
  ${({ theme }) => `
    padding: ${theme.space.xl} 0;
    color: ${theme.colors.primary};
  `}
`

export const CalculatorTotals = styled.div`
  ${({ theme, format = 'accent3' }) => `
    color: ${theme.formats[format].color};
    background-color: ${theme.formats[format].background};
    padding: ${theme.space.sm} 0;
    flex-basis: 35%;
    border-radius: 0;
    @media (min-width: ${theme.breakpoints.lg}) {
      border-radius: 0 15px 0 0;
    }
  `}
`

export const TitleRow = styled(Row)`
  margin: 0;
  text-align: center;
  ${({ theme }) => `
    padding-bottom: ${theme.space.sm};
  `}
`

export const StepperRow = styled(Row)`
  ${({ theme }) => `
    padding: ${theme.space.sm};
  `}
`

export const AgentsRow = styled(Row)`
  display: flex;
  align-items: center;
  ${({ theme }) => `
    padding: 0 ${theme.space.xxl};
    input[type=range]::-webkit-slider-thumb {
      border: none;
      margin-top: -9px;
      height: ${theme.lineHeights.lg};
      width: ${theme.lineHeights.lg};
      background: ${theme.colors.dark};
    }
  `}
  input[type=range]::-webkit-slider-runnable-track {
    background: #e8ebed; // NOTE: This is the default garden disabled color for stepper
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #e8ebed; // NOTE: This is the default garden disabled color for stepper
  }
`

export const TicketsRow = styled(Row)`
  ${({ theme }) => `
    padding: ${theme.space.sm} ${theme.space.xxl};
    text-align: center;
    .react-numeric-input input {
      font-family: ${theme.typography.heading.default};
      font-size: 2em !important;
      @media (min-width: ${theme.breakpoints.lg}) {
        font-size: 3em !important;
      }
      line-height: 1;
      font-weight: 500;
      border: 0 !important;
      text-align: center !important;
      padding: 0 30px !important;
      min-width: 200px;
    }
    b {
      cursor: pointer!important;
      border-color: 0 !important;
      border-style: none !important;
      background: none !important;
      box-shadow: none !important;
    }
  `}
`

export const FootnoteRow = styled(Row)`
  ${({ theme }) => `
    font-size: ${theme.typography.sizes.SM};
    padding: ${theme.space.sm} ${theme.space.xxl} 0 ${theme.space.xxl};
  `}
`

export const ConfigureCalculation = styled.div`
  text-align: right;
`

export const StepperStep = styled(Stepper.Step)`
  cursor: pointer;
  height: 60px;
  ${({ theme }) => `
    margin-bottom: ${theme.space.sm};
  `}
  div:first-child {
    right: calc(50% );
    left: calc(-50%);
  }
  svg {
    display: none;
  }
`

export const StepperLabel = styled(Stepper.Label)`
  position: absolute;
  z-index: +1;
  width: 100%;
  div {
    padding: 0;
    width: auto;
  }
  ${({ theme, isActive }) => {
    const fontStyles = `
      font-size: ${theme.typography.sizes.XS};
      @media (min-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.typography.sizes.MD};
      }
    `
    return `
      color: ${theme.colors.primary};
      font-family: ${theme.typography.heading.default};
      font-weight: 500;
      ${fontStyles}
      ${isActive ? `
        font-weight: 500;
        div > div {
          background-color: ${theme.colors.dark};
          box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
        }
      ` : `
        div > div {
          background-color: ${theme.colors.tertiary};
        }
      `}
    `
  }}
`

export const AgentsLabel = styled(Label)`
  flex-basis: 80px;
  text-align: center;
  ${({ theme }) => `
    padding: ${theme.space.xs};
  `}
`

export const AgentsLabelFigure = styled(Label)`
  display: block;
  ${({ theme }) => `
    font-family: ${theme.typography.heading.default};
  `}
  font-size: 2em;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 3.5em;
  }
  line-height: 1;
  font-weight: 500;
`

export const AgentsLabelSub = styled(Label)`
  ${({ theme }) => `
    display: block;
    font-size: ${theme.typography.sizes.SM};
    text-transform: uppercase;
    margin-top: ${theme.space.xxs}
    color: ${theme.colors.toAlpha(theme.colors.primary)};
  `}
`

export const AgentsRange = styled(Range)`
  ${({ theme }) => `
    padding: ${theme.space.xs};
  `}
  flex-basis: calc(100% - 120px);
  margin-top: 0!important;
  -webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
  }
`

export const StyledLabel = styled(Label)`
  ${({ theme }) => `
    display: block;
    text-transform: uppercase;
    font-size: ${theme.typography.sizes.SM};
    margin-top: ${theme.space.xxs}
    color: ${theme.colors.toAlpha(theme.colors.primary)};
  `}
`

// NOTE: use shared StyledNumeric component??
export const StyledNumeric = styled(NumericInput).attrs(({
  size,
  isCompact = size === 'small',
  isFull = size === 'full',
  min: _min = 0,
  max: _max = isCompact ? 100 : undefined,
}) => {
  const min = ensureNumeric(_min)

  // eslint-disable-next-line eqeqeq
  const max = _max != undefined ? ensureNumeric(_max) : _max

  // eslint-disable-next-line no-nested-ternary
  const width = isFull
    ? '100%'
    : isCompact
      ? 40
      : 50

  return {
    strict: true,
    min,
    ...max && { max },
    style: {
      ...isFull && {
        wrap: {
          width,
        },
      },
      input: {
        width,
      },
    },
  }
})``

export const TotalsWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const TotalBox = styled.div`
  display: flex;  
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-basis: 50%;
  ${({ theme }) => `
    padding: ${theme.space.md} 0;
    @media (min-width: ${theme.breakpoints.sm}) {
      flex-basis: 25%;
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      flex-basis: 50%;
      padding: 0;
    }
  `}
`

export const TotalAmount = styled.div`
  font-size: 2em;
  line-height: 1;
  ${({ theme }) => `
    font-family: ${theme.typography.heading.default};
    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 3.5em;
    }
  `}
`

export const TotalLabel = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.sizes.SM};
    text-transform: uppercase;
    margin-top: ${theme.space.xxs}
    color: ${theme.colors.toAlpha(theme.colors.dark)};
  `}
`

export const TotalSubAmount = styled.span`
  font-size: 0.5em;
`
