/* eslint-disable prefer-destructuring */
import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import { stringNotEmpty } from '@agnostack/lib-core'

import { StyledMarkdown, StyledMDX } from '../styled'

const GATSBY_URL_HUBSPOT_MEETING = process.env.GATSBY_URL_HUBSPOT_MEETING
const GATSBY_URL_ZENDESK_AGNOSTACK_LISTING = process.env.GATSBY_URL_ZENDESK_AGNOSTACK_LISTING
const GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING = process.env.GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING
const GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING = process.env.GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING
const GATSBY_URL_ZENDESK_MAGENTO2_LISTING = process.env.GATSBY_URL_ZENDESK_MAGENTO2_LISTING
const GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING = process.env.GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING
const GATSBY_URL_PREMIUM_ZENDESK_SHOPIFY_LISTING = process.env.GATSBY_URL_PREMIUM_ZENDESK_SHOPIFY_LISTING
const GATSBY_URL_ZENDESK_SHOPIFY_LISTING = process.env.GATSBY_URL_ZENDESK_SHOPIFY_LISTING
const GATSBY_SITE_FLAGS = process.env.GATSBY_SITE_FLAGS

const RenderMarkdown = ({
  className,
  overrides,
  node: {
    rawBody,
    rawMarkdownBody,
    html,
    body,
  },
}) => {
  const content = html || rawBody || rawMarkdownBody

  // TODO: not sure this process env destructure will work w gatsby
  switch (true) {
    case stringNotEmpty(body): {
      return (
        <StyledMDX className={className}>
          <MDXProvider components={overrides}>
            <MDXRenderer
              {...{
                process: {
                  env: {
                    GATSBY_URL_HUBSPOT_MEETING,
                    GATSBY_URL_ZENDESK_AGNOSTACK_LISTING,
                    GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING,
                    GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING,
                    GATSBY_URL_ZENDESK_MAGENTO2_LISTING,
                    GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING,
                    GATSBY_URL_PREMIUM_ZENDESK_SHOPIFY_LISTING,
                    GATSBY_URL_ZENDESK_SHOPIFY_LISTING,
                    GATSBY_SITE_FLAGS,
                  },
                },
              }}
            >
              {body}
            </MDXRenderer>
          </MDXProvider>
        </StyledMDX>
      )
    }
    case stringNotEmpty(content): {
      return (
        <StyledMarkdown options={{ overrides }}>
          {content}
        </StyledMarkdown>
      )
    }
    default: {
      return null
    }
  }
}

export default RenderMarkdown
