import React, { Suspense } from 'react'

/*
import React, { lazy } from 'react'

const LazyComponent = lazy(() => import('./LazyComponent'))

export const SomethingLazyComponent = (props) => (
  <Lazy component={LazyComponent} {...props} />
)
*/

const Lazy = ({
  component: Component = null,
  fallback = (<></>),
  ...props
}) => (
  <Suspense fallback={fallback}>
    <Component {...props} />
  </Suspense>
)

export default Lazy
