import styled from 'styled-components'

export const Emoji = styled.span.attrs(() => ({
  role: 'img',
}))`
  ${({ theme: { space: { xs = '8px' } = {} } = {} }) => `
    padding-left: ${xs};
    padding-right: ${xs};
  `}
`
