/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react'
import { window } from 'browser-monads-ts'
import { withTheme } from 'styled-components'
import { parse as parseHTML } from 'node-html-parser'
import { Button } from '@zendeskgarden/react-buttons'
import { Well, Paragraph } from '@zendeskgarden/react-notifications'

import { stringNotEmpty } from '@agnostack/lib-core'
import { DATALAYER } from '@agnostack/lib-utils-js'

import { useDimensions, useCodeSnippet } from '../../../hooks'
import { Anchor, Heading4 } from '../../../styles/core'
import SubdomainInput from './SubdomainInput'
import {
  Snippet,
  SubTitle,
  MainTitle,
  TitleWrapper,
  ToggleWrapper,
  SnippetWrapper,
  ButtonParagraph,
  ParagraphWrapper,
} from './ZendeskWebWidget.style'

// eslint-disable-next-line prefer-destructuring
const GATSBY_AGNO_GTM_ID = process.env.GATSBY_AGNO_GTM_ID

const { ZENDESK_SUBDOMAIN, ZENDESK_SNIPPET_KEY } = DATALAYER

const getParsedKey = (html) => {
  const getSrc = (parsed) => (parsed && parsed.querySelector('script')
    ? parsed.querySelector('script').attributes.src
    : null
  )
  const getKey = (src) => (
    // NOTE: IE not supported
    src && new URL(src).searchParams.get('key')
  )

  let key

  try {
    key = html && getKey(getSrc(parseHTML(html)))
  } catch (warn) {
    console.warn('Ignoring error parsing key', warn)
  }

  return key
}

const PanelHeader = ({ showManualInput, setShowManualInput }) => (
  <TitleWrapper>
    <MainTitle>
      {showManualInput
        ? 'Enter Default Zendesk Snippet'
        : 'Enter Zendesk Subdomain'}
    </MainTitle>
    <ToggleWrapper>
      <Anchor
        onClick={() => setShowManualInput(!showManualInput)}
      >
        {showManualInput ? 'Configuration Wizard' : 'Manual Setup'}
      </Anchor>
    </ToggleWrapper>
  </TitleWrapper>
)

const DefaultSnippet = ({ input }) => (
  <>
    <SubTitle>Default Zendesk Snippet</SubTitle>
    {input}
  </>
)

const EnhancedSnippet = ({ input, copyToClipboard }) => (
  <SnippetWrapper>
    <Heading4>Copy and Paste Enhanced Snippet Below</Heading4>
    <Paragraph>
      Paste the code below into each page of your website where you&apos;d like the
      Web Widget to appear (or into a template that will insert it on every
      page on the site).
    </Paragraph>
    <Paragraph>{input}</Paragraph>
    <ParagraphWrapper>
      <ButtonParagraph>
        Paste the code above as high as possible into the
        {' '}
        <Snippet>
          {'<head>'}
        </Snippet>
        {' '}
        of your page.
      </ButtonParagraph>
      <Button size="small" isPrimary onClick={() => copyToClipboard()}>
        Copy
      </Button>
    </ParagraphWrapper>
  </SnippetWrapper>
)

const ZendeskWebWidget = ({ theme }) => {
  const [showManualInput, setShowManualInput] = useState(false)
  const [defaultSnippet, setDefaultSnippet] = useState()
  const [snippetKey, setSnippetKey] = useState()
  const [subdomain, setSubdomain] = useState()
  const [{ width }] = useDimensions(window)

  const { breakpoints } = theme || {}
  const { sm: breakpointPx = '', numeric } = breakpoints || {}
  const breakpoint = numeric(breakpointPx)

  const showEnhancedInput = useMemo(() => (
    stringNotEmpty(snippetKey || subdomain)
  ), [snippetKey, subdomain])

  const [defaultCodeInput] = useCodeSnippet({
    autoFocus: !showEnhancedInput,
    muted: showEnhancedInput,
    rows: width > breakpoint ? '4' : '6',
    placeholder:
      'For Manual Setup, copy the default snippet from inside of Zendesk under the Admin Center > Channels > Messaging and social > Messaging > Edit (Menu) > Installation',
    children: defaultSnippet,
    onChange: ({ target: { value } }) => setDefaultSnippet(value),
  })

  const [enhancedCodeInput, copyToClipboard] = useCodeSnippet({
    rows: '12',
    readOnly: true,
    highlight: true,
    clipboard: true,
    children: `<!-- Start of Zendesk/agnoStack Widget script -->
<script>window.dataLayer = window.dataLayer || [];window.dataLayer.push((() => ({ ${snippetKey ? `'${ZENDESK_SNIPPET_KEY}': '${snippetKey}'` : ''}${snippetKey && subdomain ? ', ' : ''}${subdomain ? `'${ZENDESK_SUBDOMAIN}': '${subdomain}'` : ''} }))()); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${GATSBY_AGNO_GTM_ID}');</script>
<!-- End of Zendesk/agnoStack Widget script -->`,
  })

  const handleSetSnippetKey = (snippet) => {
    const key = getParsedKey(snippet)
    if (key) {
      if (snippetKey !== key) {
        setSnippetKey(key)
      }
    } else {
      setSnippetKey()
    }
  }

  useEffect(() => {
    handleSetSnippetKey(defaultSnippet)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSnippet])

  return (
    <Well isFloating isRecessed>
      <PanelHeader showManualInput={showManualInput} setShowManualInput={setShowManualInput} />
      {!showManualInput ? (
        <SubdomainInput subdomain={subdomain} onSubmit={setSubdomain} />
      ) : (
        <DefaultSnippet input={defaultCodeInput} />
      )}
      {showEnhancedInput && (
        <EnhancedSnippet input={enhancedCodeInput} copyToClipboard={copyToClipboard} />
      )}
    </Well>
  )
}

export default withTheme(ZendeskWebWidget)
