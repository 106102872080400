import { lighten, darken } from 'polished'

import { ensureObject } from '@agnostack/lib-core'

const COLORS = {
  black: '#0f0d19',
  plum: '#12333e',
  tan: '#d2b193',
  purple: '#682860',
  grey: '#e9e8e8',
  blue: '#5b6ec6',
}

const extendedPalette = Object.entries(COLORS).reduce((_extendedPalette, [key, value]) => ({
  ..._extendedPalette,
  [key]: {
    100: lighten(0.5, value),
    200: lighten(0.4, value),
    300: lighten(0.3, value),
    400: lighten(0.2, value),
    500: lighten(0.1, value),
    600: value,
    700: darken(0.1, value),
    800: darken(0.2, value),
  },
}), {})

const enhanceColor = (color) => {
  const colorBase = color[400]

  return {
    ...color,
    100: lighten(0.4, colorBase),
    200: lighten(0.35, colorBase),
    300: lighten(0.3, colorBase),
    400: lighten(0.2, colorBase),
    500: lighten(0.1, colorBase),
    700: darken(0.1, colorBase),
    800: darken(0.15, colorBase),
  }
}

export const extendTheme = ({ breakpoints, colors, palette: { lemon, crimson, ...palette }, ...theme } = {}) => {
  const enhancedPalette = Object.entries({ lemon, crimson }).reduce((_enhancedPalette, [colorName, color]) => ({
    ..._enhancedPalette,
    [colorName]: enhanceColor(color),
  }), palette)

  const { xxl = '1440px' } = ensureObject(breakpoints)

  return {
    ...theme,
    colors: {
      ...colors,
      highlightHue: 'lemon',
    },
    palette: {
      extended: extendedPalette,
      ...enhancedPalette,
    },
    breakpoints: {
      ...breakpoints,
      xxl,
    },
  }
}
