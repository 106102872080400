/* eslint-disable no-underscore-dangle */
import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'

import { isString } from '@agnostack/lib-utils-js'
import { browseable } from '@agnostack/lib-utils-react'
import { splitCommas, stringNotEmpty } from '@agnostack/lib-core'

import { GlobalState, GLOBAL_PARAMS } from '../../../util'

// eslint-disable-next-line prefer-destructuring
const GATSBY_SITE_FLAGS = process.env.GATSBY_SITE_FLAGS // TODO: add more complex flaggable component
const flags = splitCommas(GATSBY_SITE_FLAGS)

const ConditionalContent = ({ children, className, ...renderProps }) => {
  const { [GLOBAL_PARAMS.LISTING]: listingContext } = useContext(GlobalState)

  const { conditionalListing, showChildren, dataProps } = useMemo(() => {
    const { flag, unflag, listing } = renderProps
    const _dataProps = Object.entries(renderProps).reduce((__dataProps, [key, value]) => ({
      ...__dataProps,
      ...isString(value) && {
        [`data-${key}`]: value,
      },
    }), {})

    const hasFlag = stringNotEmpty(flag)
    const hasUnflag = stringNotEmpty(unflag)
    const hasListing = stringNotEmpty(listing)
    const validListing = (listingContext && (listingContext === listing))
    const validFlagged = (hasFlag && flags.includes(flag))
    const validUnflagged = (hasUnflag && !flags.includes(unflag))

    let _showChildren = false
    switch (true) {
      case (validListing && !hasFlag && !hasUnflag): {
        _showChildren = true
        break
      }

      case validListing: {
        _showChildren = (validFlagged || validUnflagged)
        break
      }

      case (!hasListing && (validFlagged || validUnflagged)): {
        _showChildren = true
        break
      }

      default: {
        break
      }
    }

    return {
      showChildren: _showChildren,
      conditionalListing: listing,
      dataProps: _dataProps,
    }
  }, [listingContext, renderProps])

  if (!showChildren) {
    return null
  }

  return (
    <div data-type="conditional-content" data-listing={conditionalListing} className={classNames('conditionalContent', className)} {...dataProps}>
      {children}
    </div>
  )
}

export default browseable(ConditionalContent)
