import React, { useMemo, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { globalHistory } from '@reach/router'

import { ensureObject } from '@agnostack/lib-core'
import { hashCode } from '@agnostack/lib-utils-js'

import {
  GlobalDispatch,
  GLOBAL_ACTIONS,
  TITLE_SCHEDULE_DEMO,
} from '../../../util'
import ScheduleModal from '../../molecules/ScheduleModal'

import { StyledAnchor } from './ScheduleAnchor.style'

const ScheduleAnchor = ({ children = TITLE_SCHEDULE_DEMO, ...renderProps }) => {
  const {
    location: {
      pathname,
    } = {},
  } = ensureObject(globalHistory)
  const guid = useMemo(() => (pathname ? `${hashCode(pathname)}-booking` : uuidv4()), [pathname])
  const dispatch = useContext(GlobalDispatch)

  return (
    <>
      <ScheduleModal id={guid} />
      <StyledAnchor
        {...renderProps}
        onClick={() => {
          dispatch({
            type: GLOBAL_ACTIONS.DISPATCH_MODAL,
            id: guid,
            visible: true,
            title: children,
          })
        }}
      >
        {children}
      </StyledAnchor>
    </>
  )
}

export default ScheduleAnchor
