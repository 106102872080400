import { useLayoutEffect, useEffect } from 'react'

export const useAsyncLayoutEffect = (asyncFn, deps, destroy) => {
  useLayoutEffect((...args) => {
    asyncFn(...args)

    return () => (
      destroy?.()
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}

export const useAsyncEffect = (asyncFn, deps, destroy) => {
  useEffect((...args) => {
    asyncFn(...args)

    return () => (
      destroy?.()
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}
