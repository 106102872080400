import React from 'react'

import { DependencyError } from '@agnostack/lib-utils-js'

import { useAsyncMemo } from '../../hooks'
import { hasWindow } from '../../utils'
import { browseable } from '../hoc'

export const loadPolyfill = async () => (
  // eslint-disable-next-line import/no-extraneous-dependencies
  import('@time-input-polyfill/react')
    .then(({ TimeInputPolyfill }) => TimeInputPolyfill)
    .catch((_ignore) => {
      throw new DependencyError('@time-input-polyfill/react')
    })
)

const Fallback = () => (
  <></>
)

const getPolyfill = async (fallback) => (
  hasWindow
    ? loadPolyfill().catch()
    : fallback
)

const TimeInputPolyfill = browseable(({ fallback = Fallback, ...renderProps }) => {
  const LazyTimeInputPolyfill = useAsyncMemo(async () => (
    getPolyfill(fallback)
  ), [hasWindow])

  if (!LazyTimeInputPolyfill) {
    return null
  }

  return (
    <LazyTimeInputPolyfill {...renderProps} />
  )
})

export default TimeInputPolyfill
