import { useState } from 'react'
import { window } from 'browser-monads-ts'

import { hasMediaQuery } from '../utils'
import { useSafeEffect } from './useSafeEffect'

// NOTE: based on implementation from ChakraUI
// https://github.com/chakra-ui/chakra-ui/blob/75edcf41e7ff4acc2569f2169949063c164d8f6e/packages/media-query/src/use-media-query.ts
export const useMediaQuery = (query) => {
  const queries = Array.isArray(query) ? query : [query]

  const [matches, setMatches] = useState(
    queries.map((_query) => (
      hasMediaQuery ? !!window.matchMedia(_query).matches : false
    ))
  )

  useSafeEffect(() => {
    if (!hasMediaQuery) return undefined

    const mediaQueryList = queries.map((_query) => window.matchMedia(_query))

    const listenerList = mediaQueryList.map((mediaQuery, index) => {
      const listener = () => setMatches((prev) => prev.map((prevValue, idx) => (
        index === idx ? !!mediaQuery.matches : prevValue
      )))

      mediaQuery.addListener(listener)

      return listener
    })

    return () => {
      mediaQueryList.forEach((mediaQuery, index) => {
        mediaQuery.removeListener(listenerList[index])
      })
    }
  }, [query])

  return matches
}
