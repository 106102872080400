import React from 'react'
import styled from 'styled-components'

import * as Modals from '@zendeskgarden/react-modals'
import { MODAL_TYPES } from '../../../util'

// NOTE: for some reason this package blows up in Gatsby SSR as import { Body } from '@zendeskgarden/react-modals'
const { Modal = 'div', Body = 'div' } = Modals

const IFrameWrapper = styled.div`
  flex-grow: 1;
  ${({ background }) => background && `
    background-color: ${background};
  `}
`

const IFrame = styled.iframe.attrs(({ url }) => ({
  src: url,
  url: undefined,
}))`
  width: 100%;
  height: 100%;
  ${({ background }) => background && `
    background-color: ${background};
  `}
`

const IFrameBody = ({ url, title, background, children, ...renderProps }) => (
  <IFrameWrapper background={background} {...renderProps}>
    <IFrame
      url={url}
      title={title}
      background={background}
    />
  </IFrameWrapper>
)

const StyledBody = styled(Body)`
  ${({ background }) => background && `
    background-color: ${background};
  `}
  ${({ theme }) => theme?.breakpoints?.xs && `
    @media (max-width: ${theme.breakpoints.xs}) {
      display: flex;
      align-items: center;
    }
  `}
`

export const ModalBody = ({ url, type, ...renderProps }) => {
  const BodyType = (url || (type === MODAL_TYPES.IFRAME))
    ? IFrameBody
    : StyledBody

  return (
    <BodyType url={url} {...renderProps} />
  )
}

export const StyledModal = styled(Modal).attrs(({ isLarge = true }) => ({
  isLarge,
}))`
  ${({ url }) => (url ? `
    height: 100%;
  ` : `
    max-height: 95%;
  `)}
`
