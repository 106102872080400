import React from 'react'

import { getNavigationLinks } from '../../../gatsby'
import ScreenshotButton from '../ScreenshotButton'
import {
  NavigationWrapper,
  NavigationHeading,
  ScreenshotsWrapper,
} from './NavigationScreenshots.style'

const NavigationScreenshots = ({
  title,
  ...renderProps
}) => {
  const navigationLinks = getNavigationLinks(renderProps)
  return (
    <NavigationWrapper isRecessed>
      <NavigationHeading>{title}</NavigationHeading>
      <ScreenshotsWrapper>
        {navigationLinks.map((navigationItem, index) => (
          <ScreenshotButton
            key={`navigationItem-${index}`}
            {...navigationItem}
          />
        ))}
      </ScreenshotsWrapper>
    </NavigationWrapper>
  )
}

export default NavigationScreenshots
