import styled from 'styled-components'

export const CountWrapper = styled.span`
  display: inline-block;

  &.ctr-enter {
    background: transparent;
    transform: translateY(100%);
  }

  &.ctr-enter.ctr-enter-active {
    transform: translateY(0);
    transition: all 300ms;
  }

  &.ctr-exit {
    transform: translateY(0);
    /* IMPORTANT: so the exiting item will be lined up with the entering item
    , creating a continuous flow effect */
    position: absolute;
    left: 0;
  }

  &.ctr-exit.ctr-exit-active {
    transform: translateY(-100%);
    transition: all 300ms;
  }
`

export const Container = styled.span`
  overflow: hidden;

  & > span {
    overflow: hidden;
    position: relative;
  }
`
