import React from 'react'
import { window } from 'browser-monads-ts'

import AppListings from '../AppListings'
import { LISTINGS } from '../../../util'

const ClickableAppListings = (props) => {
  const handleSelect = (listing) => {
    const listingUrl = LISTINGS[listing]?.url
    if (listingUrl) {
      window.open(listingUrl, '_blank')
    }
  }

  return (
    <AppListings
      {...props}
      onSelect={handleSelect}
    />
  )
}

export default ClickableAppListings
