import { useEffect, useRef } from 'react'

import { delay } from '@agnostack/lib-utils-js'

export const useDelayed = (cleanup) => {
  const delayRef = useRef(null)

  const cancelDelay = () => {
    cleanup?.()
    delayRef.current?.cancel?.()
    delayRef.current = null
  }

  useEffect(() => (
    () => {
      cancelDelay()
    }
  ), [])

  const startDelay = (time) => {
    if (delayRef.current) {
      delayRef.current.cancel?.()
    }

    delayRef.current = delay(time)
    return delayRef.current
  }

  return [startDelay, cancelDelay]
}
