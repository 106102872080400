import styled from 'styled-components'

import { SelectableAnchor } from '../styled'

// eslint-disable-next-line import/prefer-default-export
export const StyledAnchor = styled(SelectableAnchor)`
  font: unset;
  font-size: unset;
  
  && {
    color: unset!important;
  }
`
