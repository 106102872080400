import styled from 'styled-components'

export const OverlayWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    margin: 2em 0 3em 0;
    @media (max-width: ${theme.breakpoints.calc(theme.breakpoints.md, 'max')}) {
      display: none;
    }
  `}
`

export const OverlayContainer = styled.div`
  ${({ theme }) => `
    padding: 1em;
    border-radius: 15px;
    max-width: ${theme.breakpoints.xxl};
    box-shadow: 0 15px 50px ${theme.formats.default.color};
  `}
`
