import { useState, useEffect, useReducer } from 'react'
import { window } from 'browser-monads-ts'

import { ensureObject } from '@agnostack/lib-core'

const GTM_EVENT = 'agnoStack.events.gtmEvent'

let defaultInitialized = false
export const useGTM = ({ id: gtmID, onEvent, onUpdate } = {}, container = window, dataLayerName = 'dataLayer') => {
  const [fallbackData, updateFallbackData] = useReducer((previousFallbackData, updatedFallbackData) => ({
    ...previousFallbackData,
    ...updatedFallbackData,
  }), {})

  const {
    [dataLayerName]: dataLayer = [],
    google_tag_manager: {
      [gtmID]: {
        [dataLayerName]: dataManager,
      } = {},
    } = {},
  } = ensureObject(container)

  const pushData = (updatedData) => {
    if (updatedData) {
      container.dispatchEvent?.(new CustomEvent(GTM_EVENT, { detail: updatedData }))
    }
  }

  const handleGTMDefault = (event) => {
    const {
      detail,
    } = event ?? {}

    if (detail) {
      if (!gtmID) {
        updateFallbackData(detail)
      }

      dataLayer.push(detail)
    }
  }

  const handleGTMExtended = (event) => {
    const {
      detail,
      detail: {
        event: eventName,
        ...data
      },
    } = event ?? {}

    if (detail) {
      // eslint-disable-next-line eqeqeq
      if (eventName && (onEvent != undefined)) {
        onEvent(eventName, data)
      }

      onUpdate?.(detail)
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    container.addEventListener?.(GTM_EVENT, handleGTMExtended)
    if (!defaultInitialized) {
      container.addEventListener?.(GTM_EVENT, handleGTMDefault)
      defaultInitialized = true
    }

    return () => {
      container.removeEventListener?.(GTM_EVENT, handleGTMExtended)
      container.removeEventListener?.(GTM_EVENT, handleGTMDefault)
    }
  }, [])

  if (!gtmID) {
    return { dataLayer: fallbackData, pushData, findData: (dataKey) => fallbackData[dataKey] }
  }

  return { dataLayer, pushData, findData: (dataKey) => dataManager?.get?.(dataKey) ?? undefined }
}

export const useDataLayer = (gtmID, dataKey, container = window, dataLayerName = 'dataLayer') => {
  const [itemData, setItemData] = useState()

  const handleGTMUpdate = (dataUpdate) => {
    if (dataUpdate?.[dataKey]) {
      setItemData(dataUpdate[dataKey])
    }
  }

  const { pushData, findData } = useGTM({ id: gtmID, onUpdate: handleGTMUpdate }, container, dataLayerName)

  useEffect(() => {
    setItemData(findData(dataKey))
  }, [dataKey])

  const pushItemData = (updatedItemData) => pushData({ [dataKey]: updatedItemData })

  return [itemData, pushItemData]
}

export const useDataLayerEvent = (gtmID, eventKey, container = window, dataLayerName = 'dataLayer') => {
  const [eventData, setEventData] = useState()

  const handleGTMEvent = (eventName, eventDetail) => {
    if (eventName === eventKey) {
      setEventData(eventDetail)
    }
  }

  const { pushData } = useGTM({ id: gtmID, onEvent: handleGTMEvent }, container, dataLayerName)

  useEffect(() => {
    const lastMatchingEvent = container.dataLayer?.findLast?.(({ event } = {}) => (
      event === eventKey
    ))

    if (lastMatchingEvent) {
      setEventData(lastMatchingEvent)
    }
  }, [eventKey])

  const pushEventData = (dataUpdate) => pushData({
    event: eventKey,
    ...dataUpdate,
  })

  return [eventData, pushEventData]
}
