import React from 'react'

import { compareNumber } from '@agnostack/lib-core'
import { DATA_ITEM_TYPES } from '@agnostack/lib-utils-js'

const DATA_ITEM_TYPE_NAMES = Object.values(DATA_ITEM_TYPES)
  .sort((value1, value2) => compareNumber(value1.length, value2.length))

const DataItemTypes = (props) => (
  <ul {...props}>
    {DATA_ITEM_TYPE_NAMES.map((dataItemType) => (
      <li key={`data-item-type-${dataItemType}`}>
        <code>{dataItemType}</code>
      </li>
    ))}
  </ul>
)

export default DataItemTypes
