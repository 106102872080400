import { parseProject } from '@agnostack/lib-core'

import packageInfo from '../package.json'
import { stringEndsWith } from './display'

const [{ appOwner }] = parseProject(packageInfo)

const EVENT_APP_ROUTE_CHANGED = 'app.route.changed'
const EVENT_PANE_ACTIVATED = 'pane.activated'

const EVENT_NOTIFY_USERS = 'notify.users'
const EVENT_ORDER_CREATED = 'order.created'
const EVENT_ORDER_REFUND = 'order.refund'
const EVENT_SUBSCRIPTION_ACTIVATED = 'subscription.activated'
const EVENT_SUBSCRIPTION_DEACTIVATED = 'subscription.deactivated'
const EVENT_SUBSCRIPTION_CANCELLED = 'subscription.cancelled'
const EVENT_ACTIVATION_SUCCESS = 'activation.success'
const EVENT_ACTIVATION_FAIL = 'activation.fail'
const EVENT_ERROR_FATAL = 'error.fatal'
const EVENT_RESIZE_EDITOR = 'resize.editor'

const EVENT_SHARED_ROUTE_APP = 'app.route'
const EVENT_SHARED_CONFIGURATION_UPDATED = 'configuration.updated'
const EVENT_SHARED_CONFIGURATION_UPDATE_FAIL = 'configuration.fail'
const EVENT_SHARED_APP_LOCATION_SYNC = 'app.location.sync'
const EVENT_SHARED_MACROS_STATE_SYNC = 'macros.state.sync'
const EVENT_SHARED_REMINDERS_STATE_SYNC = 'reminders.state.sync'
const EVENT_SHARED_DATA_STATE_SYNC = 'data.state.sync'
// const EVENT_SHARED_EVENTS_CREATE_ORDER = 'events.create.order' TODO: replace EVENT_ORDER_CREATED move to BG
const EVENT_SHARED_ORDERS_STATE_RELOAD = 'orders.state.reload'
const EVENT_SHARED_ORDERS_STATE_SYNC = 'orders.state.sync'
const EVENT_SHARED_ORDER_SELECTED = 'order.selected'
const EVENT_SHARED_TICKET_ORDER_SELECTED = 'ticket.order.selected'
const EVENT_SHARED_TICKET_INSERT_DATA = 'ticket.data.insert'
const EVENT_SHARED_TICKET_INSERT_DATA_FROM_MODAL = 'ticket.data.insert.modal'
const EVENT_SHARED_TRIGGER_CLOSE_MODAL = 'trigger.close.modal'
const EVENT_SHARED_TRIGGER_ANALYTICS = 'trigger.analytics'
const EVENT_SHARED_REQUEST_MACROS = 'request.macros'
const EVENT_SHARED_RESPOND_MACROS = 'respond.macros'
const EVENT_SHARED_REQUEST_REMINDERS = 'request.reminders'
const EVENT_SHARED_RESPOND_REMINDERS = 'respond.reminders'
const EVENT_SHARED_REQUEST_DATA = 'request.data'
const EVENT_SHARED_RESPOND_DATA = 'respond.data'
const EVENT_SHARED_REQUEST_DATA_DOCUMENT = 'request.data.document'
const EVENT_SHARED_RESPOND_DATA_DOCUMENT = 'respond.data.document'
const EVENT_SHARED_REQUEST_SETTINGS = 'request.settings'
const EVENT_SHARED_RESPOND_SETTINGS = 'respond.settings'
const EVENT_SHARED_REQUEST_MESSAGE = 'request.message'
const EVENT_SHARED_RESPOND_MESSAGE = 'respond.message'
const EVENT_SHARED_REQUEST_CONFIGURATION = 'request.configuration'
const EVENT_SHARED_RESPOND_CONFIGURATION = 'respond.configuration'

const NOTIFICATION_PREFIX = 'notifications.'
const APP_PREFIX = `${appOwner}.`

export const EVENT_APP_CLOSE = 'app.close'
export const EXTERNAL_EVENT_PREFIX = 'external.'

export const notificationKey = (event = '') => (
  event.replace(APP_PREFIX, NOTIFICATION_PREFIX)
)
export const isError = (event = '') => (
  stringEndsWith(event, ['.fatal', '.fail', '.error', '.deactivated', '.missing'])
)

// TODO: these should likely go somewhere into platform specific js?
export const platformEvents = {
  EVENT_APP_ROUTE_CHANGED,
  EVENT_PANE_ACTIVATED,
}

export const events = {
  EVENT_RESIZE_EDITOR: `${APP_PREFIX}${EVENT_RESIZE_EDITOR}`,
  EVENT_NOTIFY_USERS: `${APP_PREFIX}${EVENT_NOTIFY_USERS}`,
  EVENT_ORDER_CREATED: `${APP_PREFIX}${EVENT_ORDER_CREATED}`,
  EVENT_ORDER_REFUND: `${APP_PREFIX}${EVENT_ORDER_REFUND}`,
  EVENT_SUBSCRIPTION_ACTIVATED: `${APP_PREFIX}${EVENT_SUBSCRIPTION_ACTIVATED}`,
  EVENT_SUBSCRIPTION_DEACTIVATED: `${APP_PREFIX}${EVENT_SUBSCRIPTION_DEACTIVATED}`,
  EVENT_SUBSCRIPTION_CANCELLED: `${APP_PREFIX}${EVENT_SUBSCRIPTION_CANCELLED}`,
  EVENT_ACTIVATION_SUCCESS: `${APP_PREFIX}${EVENT_ACTIVATION_SUCCESS}`,
  EVENT_ACTIVATION_FAIL: `${APP_PREFIX}${EVENT_ACTIVATION_FAIL}`,
  EVENT_ERROR_FATAL: `${APP_PREFIX}${EVENT_ERROR_FATAL}`,
}

export const sharedEvents = {
  EVENT_SHARED_ROUTE_APP: `${APP_PREFIX}${EVENT_SHARED_ROUTE_APP}`,
  EVENT_SHARED_APP_LOCATION_SYNC: `${APP_PREFIX}${EVENT_SHARED_APP_LOCATION_SYNC}`,
  EVENT_SHARED_ACTIVATION_FAIL: `${APP_PREFIX}${EVENT_ACTIVATION_FAIL}`,
  EVENT_SHARED_CONFIGURATION_UPDATED: `${APP_PREFIX}${EVENT_SHARED_CONFIGURATION_UPDATED}`,
  EVENT_SHARED_CONFIGURATION_UPDATE_FAIL: `${APP_PREFIX}${EVENT_SHARED_CONFIGURATION_UPDATE_FAIL}`,
  EVENT_SHARED_MACROS_STATE_SYNC: `${APP_PREFIX}${EVENT_SHARED_MACROS_STATE_SYNC}`,
  EVENT_SHARED_REMINDERS_STATE_SYNC: `${APP_PREFIX}${EVENT_SHARED_REMINDERS_STATE_SYNC}`,
  EVENT_SHARED_DATA_STATE_SYNC: `${APP_PREFIX}${EVENT_SHARED_DATA_STATE_SYNC}`,
  EVENT_SHARED_ORDERS_STATE_RELOAD: `${APP_PREFIX}${EVENT_SHARED_ORDERS_STATE_RELOAD}`,
  EVENT_SHARED_ORDERS_STATE_SYNC: `${APP_PREFIX}${EVENT_SHARED_ORDERS_STATE_SYNC}`,
  EVENT_SHARED_ORDER_SELECTED: `${APP_PREFIX}${EVENT_SHARED_ORDER_SELECTED}`,
  EVENT_SHARED_TICKET_ORDER_SELECTED: `${APP_PREFIX}${EVENT_SHARED_TICKET_ORDER_SELECTED}`,
  EVENT_SHARED_TICKET_INSERT_DATA: `${APP_PREFIX}${EVENT_SHARED_TICKET_INSERT_DATA}`,
  EVENT_SHARED_TICKET_INSERT_DATA_FROM_MODAL: `${APP_PREFIX}${EVENT_SHARED_TICKET_INSERT_DATA_FROM_MODAL}`,
  EVENT_SHARED_TRIGGER_CLOSE_MODAL: `${APP_PREFIX}${EVENT_SHARED_TRIGGER_CLOSE_MODAL}`,
  EVENT_SHARED_TRIGGER_ANALYTICS: `${APP_PREFIX}${EVENT_SHARED_TRIGGER_ANALYTICS}`,
  EVENT_SHARED_REQUEST_MACROS: `${APP_PREFIX}${EVENT_SHARED_REQUEST_MACROS}`,
  EVENT_SHARED_RESPOND_MACROS: `${APP_PREFIX}${EVENT_SHARED_RESPOND_MACROS}`,
  EVENT_SHARED_REQUEST_REMINDERS: `${APP_PREFIX}${EVENT_SHARED_REQUEST_REMINDERS}`,
  EVENT_SHARED_RESPOND_REMINDERS: `${APP_PREFIX}${EVENT_SHARED_RESPOND_REMINDERS}`,
  EVENT_SHARED_REQUEST_DATA: `${APP_PREFIX}${EVENT_SHARED_REQUEST_DATA}`,
  EVENT_SHARED_RESPOND_DATA: `${APP_PREFIX}${EVENT_SHARED_RESPOND_DATA}`,
  EVENT_SHARED_REQUEST_DATA_DOCUMENT: `${APP_PREFIX}${EVENT_SHARED_REQUEST_DATA_DOCUMENT}`,
  EVENT_SHARED_RESPOND_DATA_DOCUMENT: `${APP_PREFIX}${EVENT_SHARED_RESPOND_DATA_DOCUMENT}`,
  EVENT_SHARED_REQUEST_SETTINGS: `${APP_PREFIX}${EVENT_SHARED_REQUEST_SETTINGS}`,
  EVENT_SHARED_RESPOND_SETTINGS: `${APP_PREFIX}${EVENT_SHARED_RESPOND_SETTINGS}`,
  EVENT_SHARED_REQUEST_MESSAGE: `${APP_PREFIX}${EVENT_SHARED_REQUEST_MESSAGE}`,
  EVENT_SHARED_RESPOND_MESSAGE: `${APP_PREFIX}${EVENT_SHARED_RESPOND_MESSAGE}`,
  EVENT_SHARED_REQUEST_CONFIGURATION: `${APP_PREFIX}${EVENT_SHARED_REQUEST_CONFIGURATION}`,
  EVENT_SHARED_RESPOND_CONFIGURATION: `${APP_PREFIX}${EVENT_SHARED_RESPOND_CONFIGURATION}`,
}
