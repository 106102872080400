import styled from 'styled-components'
import { Link } from 'gatsby'
import { Row, Grid, Col } from '@zendeskgarden/react-grid'

export const Anchor = styled.a`
  color: unset;
  white-space: nowrap;
`

export const MapImage = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;
  @media (max-width: ${({ theme: { breakpoints } = {} }) => breakpoints.sm}) {
    display: none;
  }
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (min-width: ${({ theme: { breakpoints } = {} }) => breakpoints.sm}) {
    width: min-content;
  }
`

export const AddressWrapper = styled.address`
  * {
    color: white;
    text-decoration: none;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  @media (max-width: ${({ theme: { breakpoints } = {} }) => breakpoints.sm}) {
    display: none;
  }
`

export const MenuItem = styled(Link)`
  padding: 0.1rem 0 0.5rem 0;
  margin: 0.2rem;
  color: white;
  text-decoration: none;
`

export const LogoWrapper = styled.div`
  img {
    width: auto;
    height: 44px;
  }
  @media (max-width: ${({ theme: { breakpoints } = {} }) => breakpoints.calc(breakpoints.md, 'max')}) {
    padding: 1em 1em 1em 12%;
  }
`

export const MiddleCol = styled(Col).attrs(() => ({
  align: 'center',
}))`
  ${({ theme: { breakpoints } = {} }) => `
    @media (max-width: ${breakpoints.calc(breakpoints.xs, 'max')}) {
      margin: 4em 0;
    }
    @media (max-width: ${breakpoints.calc(breakpoints.sm, 'max')}) {
      margin-bottom: 2em;
    }
  `}
`

export const ContactRow = styled(Row)`
  a {
    text-decoration: none;
  }
  @media (max-width: ${({ theme: { breakpoints } = {} }) => breakpoints.calc(breakpoints.md, 'max')}) {
    margin-bottom: 1em;
  }
`

export const SocialIcon = styled.div`
  width: 25px;
  height: auto;
  display: inline-block;
  margin: 1em 0.75em;
  ${({ theme: { colors, breakpoints } = {} }) => `
    i {
      color: ${colors.light};
      font-size: 1.5em;
    }
    @media (max-width: ${breakpoints.calc(breakpoints.md, 'max')}) {
      padding: 1.5em;
      margin: 0;
    }
  `}
`

export const FooterGrid = styled(Grid)`
  margin: 0px auto;
  ${({ theme: { breakpoints } = {} }) => `
    max-width: ${breakpoints.xxl};
    @media (max-width: ${breakpoints.md}) {
      flex-flow: column-reverse;
      padding-top: 3em;
    }
  `}
`

export const Copyright = styled.div`
  text-transform: uppercase;
  color: ${({ theme: { colors } = {} }) => colors.light};
`

export const TermsRow = styled(Row)`
  padding-top: 1em;
  a {
    text-decoration: none;
  }
  ${({ theme: { colors, breakpoints } = {} }) => `
    i {
      color: ${colors.tertiary};
    }
    @media (max-width: ${breakpoints.calc(breakpoints.md, 'max')}) {
      display: none;
    }
  `}
`

export const LinksRow = styled(Row)`
  margin-top: 2em;
  padding-top: 1em;
  a {
    text-decoration: none;
  }
  ${({ theme: { colors, breakpoints } = {} }) => `
    i {
      color: ${colors.tertiary};
    }
    border-top: .5px solid ${colors.toAlpha(colors.tertiary)};
    @media (max-width: ${breakpoints.calc(breakpoints.md, 'max')}) {
      display: none;
    }
  `}
`

export const BottomRow = styled(Row)`
  margin-top: 1.5em;
  padding-top: 1.5em;
  border-top: ${({ theme: { colors } = {} }) => `
    1px solid ${colors.toAlpha(colors.light, colors.alpha2)}
  `};
`
