import styled from 'styled-components'

import { objectNotEmpty } from '@agnostack/lib-core'

export const Backdrop = styled.div`
  ${({ theme, backdrop, offset, offsets, sectionHeight }) => `
    ${backdrop ? `
      background-color: ${theme.formats[backdrop].background};
      color: ${theme.formats[backdrop].color};
    ` : ''}

    @media (min-width: ${theme.breakpoints.calc(theme.breakpoints.md, 'min')}) {
      height: calc(${sectionHeight}px - ${(offset || objectNotEmpty(offsets)) ? offsets?.desktop || offset : '0px'} - 1rem);
    }
  `};
`
