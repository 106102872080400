/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    // eslint-disable-next-line react/state-in-constructor
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(warn) {
    console.warn('Ignoring error', warn)
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props.fallback ?? (
        <div>
          <h2>{this.props.title ?? 'Oops, there is an error!'}</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      )
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children ?? null
  }
}

export default ErrorBoundary
