import React, { useContext, useMemo } from 'react'
import { navigate } from 'gatsby'
import { window } from 'browser-monads-ts'
import { withTheme } from 'styled-components'

import { useDimensions } from '../../../hooks'
import { GlobalDispatch, GLOBAL_ACTIONS, GLOBAL_PARAMS } from '../../../util'
import { ButtonsWrapper, ListingButton } from './ListingButtons.style'

const ListingButtons = ({ theme, listing }) => {
  const [{ width }] = useDimensions(window)
  const dispatch = useContext(GlobalDispatch)

  const hidePrimary = useMemo(() => {
    const { breakpoints } = theme || {}
    const { sm: breakpointPx = '', numeric } = breakpoints || {}
    const breakpoint = numeric(breakpointPx)
    return (width > breakpoint)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  const handleNavigate = (href) => {
    dispatch({
      type: GLOBAL_ACTIONS.ACTIVE_LISTING,
      payload: { [GLOBAL_PARAMS.LISTING]: listing },
    })
    navigate(href)
  }

  return (
    <ButtonsWrapper>
      <ListingButton
        isPrimary={!hidePrimary}
        href="/faqs/setup-onboarding/install-configure-your-app"
        onClick={handleNavigate}
      >
        Learn More
      </ListingButton>
      {hidePrimary && (
        <ListingButton
          listing={listing}
          href="/faqs/setup-onboarding/install-configure-your-app"
          onClick={handleNavigate}
        >
          Install Now
        </ListingButton>
      )}
    </ButtonsWrapper>
  )
}

export default withTheme(ListingButtons)
