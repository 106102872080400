import { useEffect, useState } from 'react'

export const useAsyncMemo = (promisable, deps, initial) => {
  const [val, setVal] = useState(initial)

  useEffect(() => {
    let cancel = false

    const promise = promisable()

    // eslint-disable-next-line eqeqeq
    if (promise == undefined) return

    promise.then((_val) => {
      if (!cancel) {
        setVal(_val)
      }
    })

    // eslint-disable-next-line consistent-return
    return () => {
      cancel = true
    }
  }, deps)

  return val
}
