/* eslint-disable no-param-reassign, no-plusplus */
import {
  stringEmpty,
  stringNotEmpty,
  templateReplace,
  ensureString,
  ensureArray,
  ensureObject,
} from '@agnostack/lib-core'

import { hashCode } from './display'

export const splitAddressLine = (addressLine) => {
  const matchedAddressLine = ensureString(addressLine).match(/^([\d\s-]*)([a-zA-Z].*)$/)

  if (matchedAddressLine?.[1]) {
    return [matchedAddressLine[2].trim(), matchedAddressLine[1].trim()]
  }

  return [addressLine]
}

export const hashAddress = (address, { includeID = false } = {}) => {
  const {
    id,
    line_1,
    line_2,
    city,
    region,
    postcode,
    country,
  } = ensureObject(address)

  return hashCode(
    [
      ...includeID ? [id] : [],
      line_1,
      line_2,
      city,
      region,
      postcode,
      country
    ]
    .filter(stringNotEmpty)
    .join('')
  )
}

export const compareAddresses = (address1, address2) => (
  hashAddress(address1) === hashAddress(address2)
)

// NOTE: these functions are based on code created by @fragaria/address-formatter
const cleanupRender = (text) => {
  const replacements = [
    // eslint-disable-next-line no-useless-escape
    { s: /[\},\s]+$/u, d: '' },
    { s: /^[,\s]+/u, d: '' },
    { s: /^- /u, d: '' }, // line starting with dash due to a parameter missing
    { s: /,\s*,/u, d: ', ' }, // multiple commas to one
    { s: /[ \t]+,[ \t]+/u, d: ', ' }, // one horiz whitespace behind comma
    { s: /[ \t][ \t]+/u, d: ' ' }, // multiple horiz whitespace to one
    { s: /[ \t]\n/u, d: '\n' }, // horiz whitespace, newline to newline
    { s: /\n,/u, d: '\n' }, // newline comma to just newline
    { s: /,,+/u, d: ',' }, // multiple commas to one
    { s: /,\n/u, d: '\n' }, // comma newline to just newline
    { s: /\n[ \t]+/u, d: '\n' }, // newline plus space to newline
    { s: /\n\n+/u, d: '\n' } // multiple newline to one
  ]

  const dedupe = (_inputChunks, glue, modifier = (s) => s) => {
    const seen = {}
    const result = []
    const inputChunks = ensureArray(_inputChunks)
    // TODO: change to ES6 loops
    for (let i = 0; i < inputChunks.length; i++) {
      const chunk = ensureString(inputChunks[i]).trim()
      // Special casing New York here, no dedupe for it
      if (chunk.toLowerCase() === 'new york') {
        seen[chunk] = 1
        result.push(chunk)
        // eslint-disable-next-line no-continue
        continue
      }

      if (!seen[chunk]) {
        seen[chunk] = 1
        result.push(modifier(chunk))
      }
    }

    return result.join(glue)
  }

  // TODO: change to ES6 loops
  for (let i = 0; i < replacements.length; i++) {
    text = ensureString(text).replace(replacements[i].s, replacements[i].d)
    text = dedupe(ensureString(text).split('\n'), '\n', (s) => dedupe(ensureString(s).split(', '), ', '))
  }

  return ensureString(text).trim()
}

// NOTE: these functions are based on code created by @fragaria/address-formatter
export const formatAddress = (_address, addressFormats) => {
  const {
    id,
    type,
    first_name,
    last_name,
    name,
    meta,
    phone,
    company,
    description,
    relationships,
    ...address
  } = ensureObject(_address)
  const { country = 'US' } = address
  const { template, mappings } = ensureObject(
    ensureArray(addressFormats).find(({ code }) => code === country)
  )

  const addressMappings = ensureArray(mappings).reduce((previousMappings, { name: mappingName, mappedFields }) => {
    const value = address[mappingName]
    const mappedField = ensureArray(mappedFields)[0]

    return {
      ...previousMappings,
      ...(mappedField && value) && { [mappedField]: value },
    }
  }, {})

  const addressData = {
    ...addressMappings,
    ...address,
    first: () => (text, render) => {
      const possibilities = render(text, address)
        .split(/\s*\|\|\s*/)
        .filter((possibility) => stringNotEmpty(possibility))
      return ensureArray(possibilities)[0] || ''
    },
  }

  let render = cleanupRender(templateReplace(template, addressData))
  render = cleanupRender(render)

  if (!stringNotEmpty(render)) {
    render = cleanupRender(
      Object.keys(address)
        .map((key) => address[key])
        .filter((value) => stringNotEmpty(value))
        .join(', ')
    )
  }

  return ensureString(render).replace(/(\r\n|\n|\r)/gm, ' ')
}

export const fomatAddressOptions = (addresses, addressFormats) => {
  if (!addressFormats) {
    return []
  }

  return ensureArray(addresses).reduce((previousOptions, _address) => {
    const { id: value, ...address } = ensureObject(_address)
    if (stringEmpty(value)) {
      return previousOptions
    }

    return [
      ...previousOptions,
      {
        label: formatAddress(address, addressFormats),
        value,
      }
    ]
  }, [])
}
