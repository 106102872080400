import styled from 'styled-components'

export const VimeoPlayer = styled.iframe.attrs(({ headline }) => ({
  title: headline,
  frameBorder: '0',
  allow: 'autoplay',
}))``

export const YouTubePlayer = styled.iframe.attrs(({ headline }) => ({
  title: headline,
  frameBorder: '0',
  allow: 'autoplay',
  id: 'ytplayer',
  type: 'text/html',
}))``
