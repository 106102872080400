import { useLocation } from '@reach/router'

export const useQueryParam = (paramName) => {
  const location = useLocation()

  // eslint-disable-next-line eqeqeq
  if ((paramName == undefined) || !location?.search) {
    return undefined
  }

  // NOTE: IE not supported
  const search = new URLSearchParams(location?.search)
  return search.get(paramName)
}
