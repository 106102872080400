import React, { useMemo } from 'react'
import { window } from 'browser-monads-ts'
import { withTheme } from 'styled-components'

import { ErrorBoundary } from '@agnostack/lib-utils-react'
import { useDimensions } from '../../../hooks'

const Responsive = ({
  component: Component = 'div',
  children = null,
  theme,
  breakpoint: breakProp,
  ...renderProps
}) => {
  const [{ width }] = useDimensions(window)
  const { breakpoint, shouldBreak } = useMemo(() => {
    const { breakpoints } = theme || {}
    const { md: breakpointPx = '', numeric } = breakpoints || {}

    // eslint-disable-next-line no-underscore-dangle
    const _breakpoint = breakProp || numeric(breakpointPx)

    return {
      breakpoint: _breakpoint,
      shouldBreak: width >= _breakpoint,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakProp, width])

  const fallback = useMemo(() => {
    if (!shouldBreak) {
      return null
    }

    return (
      <ErrorBoundary fallback={<></>}>
        {children}
      </ErrorBoundary>
    )
  }, [shouldBreak, children])

  return (
    <ErrorBoundary fallback={fallback}>
      {shouldBreak ? (
        <Component breakpoint={breakpoint} {...renderProps}>
          {children}
        </Component>
      ) : (
        children
      )}
    </ErrorBoundary>
  )
}

export default withTheme(Responsive)
