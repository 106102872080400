import styled from 'styled-components'

import { LISTINGS } from '../../../util'

import LinkButton from '../LinkButton'

export const ButtonsWrapper = styled.div`
  margin-top: 1em;
`

export const ListingButton = styled(LinkButton).attrs(({ listing, href, onClick }) => ({
  ...listing && {
    target: '_blank',
    isPrimary: true,
    href: LISTINGS[listing].url,
  },
  onClick: () => onClick(href),
}))``
