import { useRef, useState, useEffect } from 'react'
import { window } from 'browser-monads-ts'

import { stringNotEmpty } from '@agnostack/lib-core'

import { useInterval } from './useInterval'

export const usePopup = (frequencyDuration) => {
  const windowRef = useRef()
  const [windowAvailable, setWindowAvailable] = useState(false)
  const [windowParams, setWindowParams] = useState({})

  const handleCheckAvailable = (checkable = windowRef?.current) => (
    !(!checkable || checkable.closed || (typeof checkable.closed === 'undefined'))
  )

  const {
    actions: {
      start: handleStart,
      stop: handleStop,
    } = {},
  } = useInterval(() => {
    const isAvailable = handleCheckAvailable()
    setWindowAvailable(isAvailable)

    if (isAvailable) {
      try {
        const currentUrl = windowRef.current?.location?.href
        if (stringNotEmpty(currentUrl)) {
          const { searchParams } = new URL(currentUrl)
          setWindowParams(Object.fromEntries(searchParams))
        }
      } catch (_ignore) {
        console.info('Ignoring error accessing popup params.', _ignore)
      }
    }
  })

  useEffect(() => {
    if (frequencyDuration) {
      if (windowAvailable) {
        handleStart(frequencyDuration)
      } else {
        handleStop()
      }
    }
  }, [windowAvailable, frequencyDuration])

  const handleClose = () => {
    if (windowAvailable) {
      handleStop()
      windowRef.current?.close?.()
      windowRef.current = undefined
    }
  }

  const handleOpen = (url, target = '_blank', features) => {
    if (windowRef?.current) {
      handleClose()
    }

    const windowInstance = window.open?.(url, target, features)
    const isAvailable = handleCheckAvailable(windowInstance)
    setWindowAvailable(isAvailable)
    windowRef.current = windowInstance

    return isAvailable ? windowRef.current : undefined
  }

  return {
    popup: windowRef?.current,
    available: windowAvailable,
    params: windowParams,
    actions: {
      start: handleStart,
      stop: handleStop,
      open: handleOpen,
      close: handleClose,
    },
  }
}
