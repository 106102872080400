import React from 'react'
import styled from 'styled-components'

import {
  Button,
  SplitButton,
  ChevronButton,
} from '@zendeskgarden/react-buttons'
import { MediaItem } from '@zendeskgarden/react-dropdowns'

import { convertElement } from '@agnostack/lib-utils-react'

// All Navigation Bars
export const NavigationWrapper = styled(
  ({ component = <section />, ...props }) => convertElement(component, props)
)`
  padding: 1.5em 3em;
  @media (max-width: ${({ theme: { breakpoints } = {} }) => breakpoints.calc(breakpoints.md, 'max')}) {
    padding: 0;
  }
  ${({ header }) => header && `
    position: absolute;
    left: 0;
    right: 0;
    transition: background-color 0.4s ease;
    z-index: +2;
  `}
  ${({ sticky, theme: { formats } = {} }) => sticky && `
    position: fixed;
    top: 0;
    background-color: ${formats.default.color};
    z-index: +2;
  `}
`

export const NavigationContentWrapper = styled.div`
  max-width: ${({ theme: { breakpoints } = {} }) => breakpoints.xxl};
  margin: 0px auto;
`

export const InstallButton = styled(Button).attrs(() => ({
  isGrouped: true,
}))`
  border: none!important;
  height: 38px;
  border-radius: 3px;
`

export const AppsButton = styled(ChevronButton).attrs(() => ({
  isGrouped: true,
}))`
  margin-right: unset;
  padding: unset;
  min-width: unset;
  border: none!important;
  border-radius: 3px;
  width: 30px;
  height: 38px;
`

export const AppItem = styled(MediaItem)`
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
`

export const SplitWrapper = styled(SplitButton)`
  ${({ theme: { colors } = {} }) => `
    margin: 1px;
    border-radius: 4px;
    border: 1px solid ${colors.toAlpha(colors.light, 0.1)};
    box-shadow: 0 2px 2px 0 ${colors.shadow1}, 2px 2px 6px 0 ${colors.shadow2}!important;
    transition: transform .2s ease, box-shadow .2s ease;

    &&:hover {
      transform: translate(0px, .5px);
      box-shadow: 0 1px 1px 0 ${colors.shadow1}, 1px 1px 6px 0 ${colors.shadow2}!important;
    }
  `}
`
