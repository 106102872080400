import styled from 'styled-components'
import { Well } from '@zendeskgarden/react-notifications'

export const CalloutContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const CalloutWell = styled(Well).attrs(({ isRecessed = true, isMultiLine = true }) => ({
  isRecessed,
  isMultiLine,
}))`
  display: flex;
  justify-content: center;

  ${({ isNarrow }) => !isNarrow && 'flex-grow: 1;'}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  ${({ theme, format }) => format && `
    background-color: ${theme.formats[theme.formats[format].compliment].background};
    color: ${theme.formats[theme.formats[format].compliment].color};
  `}

  ${({ isDanger, theme }) => isDanger && `
    background-color: ${theme.palette.crimson['600']};
    color: ${theme.colors.light};
  `}
`

export const CalloutWrapper = styled.div`
  display: flex;
  text-align: center;
`
