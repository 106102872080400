import React from 'react'
import { navigate } from 'gatsby'
import { Button } from '@zendeskgarden/react-buttons'

import { getNavigationLinks } from '../../../gatsby'

const NavigationButtons = (props) => {
  const navigationLinks = getNavigationLinks(props)
  return navigationLinks.map(({ title, path: linkPath, text }, index) => (
    <Button
      value={text}
      isPill
      size="small"
      key={`navigationButton-${index}`}
      disabled={!linkPath}
      title={title}
      onClick={() => linkPath && navigate(linkPath)}
    >
      {text}
    </Button>
  ))
}

export default NavigationButtons
