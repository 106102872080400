import { useEffect } from 'react'

import { ensureArray } from '@agnostack/lib-core'

import { useDelayed } from './useDelayed'

export const useDelay = (time = 100, callback = () => {}, deps) => {
  const [startDelay, cancelDelay] = useDelayed(() => {})

  useEffect(() => {
    const timer = startDelay(time)
    timer.then(callback)

    return () => {
      cancelDelay()
    }
  }, [startDelay, cancelDelay, time, ...ensureArray(deps)])
}
