import styled from 'styled-components'

import { Col } from '@zendeskgarden/react-grid'

export const StyledHeader = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.calc(theme.breakpoints.md, 'max')}) {
    display: none;
  }
`

// Top Navigation Bar
export const HeaderWrapper = styled.div`
  display: grid;
  flex-wrap: nowrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding: 2em 0;
  }
  text-align: right;
  button {
    margin-left: 1em;
  }
`

export const LogoImage = styled.div`
  text-align: left;
  padding-bottom: 10px;
  align-self: flex-start;
  margin-top: 10px;
  img {
    width: auto;
    height: 30px;
  }
`

export const NavigationWrapper = styled(Col).attrs(() => ({
  md: 9,
  nowrap: true,
}))`
  display: flex;
  justify-content: flex-end;
`

export const LogoWrapper = styled(Col).attrs(() => ({
  md: 3,
}))`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.md}) {
      align-self: flex-start;
    }
  `}
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  ${({ showSearch }) => showSearch && `
    width: 100%;
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-direction: column;
  }
`

export const TextLinks = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin: 4px 0 10px;
  }
`
