import React, { useMemo } from 'react'

import { useDataLayer } from '@agnostack/lib-utils-react'
import { ensureObject } from '@agnostack/lib-core'
import { DATALAYER } from '@agnostack/lib-utils-js'

import { MODAL_TYPES, TITLE_SCHEDULE_DEMO } from '../../../util'

import StatefulModal from '../StatefulModal'

const { HUBSPOT_CONTACT_PROPERTIES_EMAIL, HUBSPOT_CONTACT } = DATALAYER

// eslint-disable-next-line prefer-destructuring
const GATSBY_GTM_ID = process.env.GATSBY_GTM_ID // NOTE: combined these fails at runtime in gatsby
// eslint-disable-next-line prefer-destructuring
const GATSBY_URL_HUBSPOT_MEETING = process.env.GATSBY_URL_HUBSPOT_MEETING

const ScheduleModal = ({ id }) => {
  const [customerEmail] = useDataLayer(GATSBY_GTM_ID, HUBSPOT_CONTACT_PROPERTIES_EMAIL)
  const [contact] = useDataLayer(GATSBY_GTM_ID, HUBSPOT_CONTACT)
  const {
    // id: contactId, // NOTE: may wantto add this
    properties: {
      firstname,
      lastname,
    } = {},
  } = ensureObject(contact)

  const src = useMemo(() => {
    const url = new URL(GATSBY_URL_HUBSPOT_MEETING)
    if (customerEmail) {
      url.searchParams.append('email', customerEmail)
    }
    if (firstname) {
      url.searchParams.append('firstname', firstname)
    }
    if (lastname) {
      url.searchParams.append('lastname', lastname)
    }
    return url.href
  }, [firstname, lastname, customerEmail])

  return !src ? null : (
    <StatefulModal
      id={id}
      title={TITLE_SCHEDULE_DEMO}
      type={MODAL_TYPES.IFRAME}
      url={src}
      // NOTE: this is the color of the BG in HubSpot (can see when scrolling)
      background="rgb(245, 248, 250)"
    />
  )
}

export default ScheduleModal
