import React from 'react'

import Heading from '../Heading'
import ListingButtons from '../ListingButtons'

import { Footer, Tagline } from './ListingFooter.style'

const ListingFooter = ({ listing = 'agnostack' }) => (
  <Footer>
    <ListingButtons listing={listing} />
    <Tagline>
      <p>agnoStack empowers retailers who understand that providing unparalleled end-to-end experience can truly differentiate your brand.</p>
      <br />
      <Heading tag="4">Customer experience doesn&apos;t stop at checkout.</Heading>
    </Tagline>
  </Footer>
)

export default ListingFooter
