import React, { useMemo, useContext } from 'react'

import { Tiles } from '@zendeskgarden/react-forms'

import { arrayNotEmpty, parseCommaGroups } from '@agnostack/lib-core'
import { ListingIcons } from '@agnostack/components-icons'

import {
  GlobalDispatch,
  GlobalState,
  GLOBAL_ACTIONS,
  GLOBAL_PARAMS,
  LISTINGS,
} from '../../../util'

import {
  AppListing,
  AppIcon,
  IconWrapper,
  ListingsContainer,
} from './AppListings.style'

const AppListings = ({ format, listing, listings, onSelect = () => {}, ...renderProps }) => {
  const dispatch = useContext(GlobalDispatch)
  const { [GLOBAL_PARAMS.LISTING]: listingContext } = useContext(GlobalState)

  const selectedListing = useMemo(() => (
    listing || listingContext
  ), [listing, listingContext])

  const listingNames = useMemo(() => (
    parseCommaGroups(listings)
  ), [listings])

  const handleClick = (listingName) => {
    dispatch({
      type: GLOBAL_ACTIONS.ACTIVE_LISTING,
      payload: { [GLOBAL_PARAMS.LISTING]: listingName },
    })

    onSelect(listingName)
  }

  return (
    <ListingsContainer
      name="listingsContaner"
      data-listing={listing}
      value={selectedListing}
      {...renderProps}
    >
      {Object.entries(LISTINGS).map(([listingName, { title }], index) => {
        const Icon = ListingIcons[listingName]
        if (arrayNotEmpty(listingNames) && (!listingNames.includes(listingName) || !Icon)) {
          return null
        }

        return (
          <AppListing
            key={`listing-${listingName}-${index}`}
            value={listingName}
            format={format}
            onClick={() => handleClick(listingName)}
          >
            <AppIcon>
              <IconWrapper>
                <Icon />
              </IconWrapper>
            </AppIcon>
            <Tiles.Label>
              {title}
            </Tiles.Label>
          </AppListing>
        )
      })}
    </ListingsContainer>
  )
}

export default AppListings
