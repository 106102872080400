import React from 'react'

import { Emoji } from '@agnostack/lib-utils-react'
import {
  stringNotEmpty,
  stringEmpty,
  dashcase,
  objectEmpty,
  ensureObject,
  ensureArray,
  ensureDateTime,
  convertFormatted,
  getDateTimeFromString,
  DATE_FORMAT_MED,
} from '@agnostack/lib-core'

import { GLOBAL_MODES } from './constants'

export const TYPE_ANNOUNCEMENT = 'announcement'

export const getAnnouncementData = (_edges, display) => {
  const edges = ensureArray(_edges)

  const announcement = ensureObject(
    edges.find(({
      node: {
        frontmatter: {
          type,
        } = {},
      } = {},
    }) => (type === TYPE_ANNOUNCEMENT)) ?? edges[0]
  )

  const {
    node: {
      frontmatter: {
        date,
        type,
      } = {},
    } = {},
  } = ensureObject(announcement)

  const showAnnouncement = (
    [GLOBAL_MODES.DEBUG, GLOBAL_MODES.PREVIEW].includes(display) ||
    (stringEmpty(date) && (type === TYPE_ANNOUNCEMENT)) ||
    (ensureDateTime() >= getDateTimeFromString({ value: date, format: DATE_FORMAT_MED }))
  )

  return showAnnouncement ? announcement : {}
}

export const formatAnnouncement = (edge) => {
  if (objectEmpty(edge)) {
    return null
  }

  const {
    node: {
      frontmatter: {
        type,
        date,
        version,
        category,
        template,
        link: _link,
        title: _title,
        ...frontmatter
      } = {},
    } = {},
  } = ensureObject(edge)

  const title = _title || `Release ${version}`
  const formattedDate = stringNotEmpty(date)
    ? convertFormatted({
      value: date,
      from: { format: DATE_FORMAT_MED },
      to: { format: 'DDD' },
    })
    : undefined

  const link = _link || `/${[dashcase(template),
    dashcase(category),
    dashcase(version)
  ].filter(stringNotEmpty).join('/')}`

  return {
    date,
    title,
    link,
    edge,
    type,
    ...frontmatter,
    summary: () => (
      <>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <Emoji aria-label="announcement">
          📣
        </Emoji>
        {formattedDate && (
          <span>
            {formattedDate}
          </span>
        )}
        <span>
          {`${formattedDate ? ': ' : ''}${_title || `Now introducing version ${version}!`}`}
        </span>
      </>
    ),
  }
}
