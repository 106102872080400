import styled from 'styled-components'

// TODO: remove garden components here and move to shared component
import { Button } from '@zendeskgarden/react-buttons'
import { Field } from '@zendeskgarden/react-forms'
import { Item, Select } from '@zendeskgarden/react-dropdowns'

export const StyledSelect = styled(Select)`
  ${({ value, theme: { colors, palette } = {} }) => value === '' && `
    && {
      color: ${palette[colors.neutralHue][400]};
    }
  `}
`

export const StyledItem = styled(Item)`
  ${({ value: { value } = {}, theme: { colors, palette } = {} }) => value === '' && `
    && {
      color: ${palette[colors.neutralHue][400]};
    }
  `}
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormContainer = styled.div`
  display: flex;
`

export const FieldsWrapper = styled.div`
  padding-bottom: 1em;
`

export const FieldWrapper = styled(Field)`
  display: flex;
  justify-content: center;
  align-items: baseline;
  input {
    display: inline-block;
    margin-top: 2px;
    width: 50vw;
    max-width: 300px;
  }
`

export const SubmitButton = styled(Button)`
  overflow: unset;
  margin: 0 4px;
`

export const MessageWrapper = styled.div`
  padding: 0.5em;
`

export const DropdownWrapper = styled.div`
  margin: 2px 0;
  text-align: left;
`
