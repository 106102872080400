/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'

import { Avatar } from '@zendeskgarden/react-avatars'
import { Button } from '@zendeskgarden/react-buttons'
import { Tag } from '@zendeskgarden/react-tags'
import { Well } from '@zendeskgarden/react-notifications'

import { components } from '@agnostack/lib-assets'
import {
  stringNotEmpty,
  stringEmpty,
  objectEmpty,
  ensureObject,
  ensureArray,
} from '@agnostack/lib-core'

import * as icons from '@agnostack/components-icons'

import * as _atoms from '../../atoms'
import { WrapperWell } from './PageFragment.style'
import ChildrenComponent from './ChildrenComponent'

const { RenderMarkdown, LinkAnchor, ...atoms } = _atoms

const shortcodes = {
  ...icons,
  ...atoms,
  ...components,
  a: LinkAnchor,
  Anchor: LinkAnchor,
  Avatar,
  Button,
  Well,
  Tag,
}

const matchFragmentData = (id, data, fragments) => {
  if (
    stringEmpty(id) ||
    (!data?.fragments && !data?.node) ||
    // TODO: handle pattern match??
    (stringNotEmpty(data?.node?.frontmatter?.match) && (data.node.frontmatter.match === id))
  ) {
    return data
  }

  if (data?.fragments) {
    return ensureArray(data?.fragments).find(({
      node: {
        frontmatter: {
          match,
        } = {},
      } = {},
    // TODO: handle pattern match??
    }) => (match === id))
  }

  return ensureArray(fragments).find(({
    node: {
      frontmatter: {
        match,
      } = {},
    } = {},
  // TODO: handle pattern match??
  }) => (match === id))
}

const PageFragment = ({ className, id, data, fragments }) => {
  const { node, childEdges } = useMemo(() => {
    const fragmentData = matchFragmentData(id, data, fragments)

    const {
      node: _node,
      node: {
        frontmatter: {
          childEdges: _childEdges,
        } = {},
      } = {},
    } = ensureObject(fragmentData)

    return {
      node: _node,
      childEdges: _childEdges,
    }
  }, [id, data, fragments])

  const Fragment = ({ id: fragmentId }) => (
    <PageFragment
      id={fragmentId}
      data={data}
      fragments={fragments}
    />
  )

  // NOTE: this is typically used to render a dropdown w/ child nodes in markdown
  const Children = (renderProps) => (
    <ChildrenComponent
      edges={childEdges}
      overrides={shortcodes}
      wrapper={WrapperWell}
      {...renderProps}
    />
  )

  if (objectEmpty(node)) {
    return null
  }

  return (
    <RenderMarkdown
      className={className}
      node={node}
      overrides={{
        ...shortcodes,
        Children,
        Fragment,
      }}
    />
  )
}

export default PageFragment
