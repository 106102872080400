import React, { useEffect, useState } from 'react'

export const browseable = (BrowseableComponent, outerProps = {}) => {
  const BrowseableHOC = (innerProps = {}) => {
    const { fallback = null, ...props } = { ...innerProps, ...outerProps }
    const [isBrowser, setIsBrowser] = useState(false)

    useEffect(() => {
      const asyncMethod = async () => {
        const {
          window: monadWindow,
          exists = () => false,
        } = await import('browser-monads-ts')

        // TODO: do we need this dynamic import/can we use monads from utils-react??
        setIsBrowser(exists(monadWindow))
      }

      asyncMethod()
    }, [])

    if (!isBrowser) {
      return fallback
    }

    return (
      <BrowseableComponent {...props} />
    )
  }

  return BrowseableHOC
}
