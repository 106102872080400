import React from 'react'
import { withTheme } from 'styled-components'

import { toFormatted, toUnixInteger } from '@agnostack/lib-core'
import { DATALAYER } from '@agnostack/lib-utils-js'

import StatefulModal from '../StatefulModal'
import { ModalWrapper, ModalContainer } from '../../../styles/core'
import { ContactForm } from '../../atoms'
import { TITLE_LIVE_DEMO_REGISTER } from '../../../util'
import { StyledHeading } from './DemoModal.style'

const {
  HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME,
  HUBSPOT_CONTACT_PROPERTIES_LAST_NAME,
} = DATALAYER

const fields = [
  {
    label: 'First Name',
    value: HUBSPOT_CONTACT_PROPERTIES_FIRST_NAME,
  },
  {
    label: 'Last Name',
    value: HUBSPOT_CONTACT_PROPERTIES_LAST_NAME,
  }
]

const DemoModal = withTheme(({ theme, id, timestamp = toUnixInteger(), onSuccess, children }) => (
  <StatefulModal center id={id} background={theme.colors.tertiary}>
    <ModalWrapper>
      <ModalContainer>
        <StyledHeading tag="2">
          {`${TITLE_LIVE_DEMO_REGISTER}:`}
        </StyledHeading>
        <StyledHeading tag="5">
          {`${toFormatted({ value: timestamp, format: 'cccc FFF' })}`}
        </StyledHeading>
        {children}
        <ContactForm
          type={`agnoStack Demo Session (${toFormatted({ value: timestamp, format: 'ccc FF ZZZZ' })})`}
          fields={fields}
          onSuccess={onSuccess}
        />
      </ModalContainer>
    </ModalWrapper>
  </StatefulModal>
))

export default React.memo(DemoModal)
