/* eslint-disable indent */
import { css } from 'styled-components'

const commonOverrides = ({ theme, format }) => format && `
  color: ${theme.formats[format].color};
  background-color: ${theme.formats[format].background}!important;
`

const buttonCss = css`
  ${commonOverrides}
  ${({ theme, disabled, isPrimary, isGrouped, highlight, format = 'default' }) => `
    margin: 0 2px;
    padding: 0 1.5em;
    text-transform: uppercase;
    font-family: ${theme.typography.heading.default};
    border-color: ${theme.colors.toAlpha(theme.colors.light, 0.1)};
    transition: background-color .3s ease, transform .2s ease, box-shadow .2s ease;
    ${!isGrouped ? `
      box-shadow: 0 2px 2px 0 ${theme.colors.shadow1}, 2px 2px 6px 0 ${theme.colors.shadow2}!important;
    ` : `
      box-shadow: 0 2px 2px 0 transparent, 2px 2px 6px 0 transparent!important;
    `}
    ${disabled ? `
      cursor: no-drop!important;
      color: ${theme.colors.disabled}!important;
    ` : `
      background-color: ${isPrimary
        ? theme.formats[theme.formats[format].compliment][highlight ? 'accent' : 'background']
        : theme.formats[theme.formats[format].compliment].accent}!important;
      color: ${isPrimary
        ? theme.formats[theme.formats[format].compliment][highlight ? 'background' : 'color']
        : theme.formats[theme.formats[format].compliment].background}!important;
      ${!isGrouped && `
        &&:hover {
          transform: translate(0px, .5px);
          box-shadow: 0 1px 1px 0 ${theme.colors.shadow1}, 1px 1px 6px 0 ${theme.colors.shadow2}!important;
        }
      `}
    `}
  `};
`

// eslint-disable-next-line import/prefer-default-export
export const garden = {
  'buttons.button': buttonCss,
  'buttons.icon_button': buttonCss,
  'forms.tile': css`
    ${({ theme }) => `
      color: ${theme.formats.default.color};
      border-color: ${theme.formats[theme.formats.default.compliment].accent};

      &&:hover {
        background-color: ${theme.formats.default.background};
        border-color: ${theme.formats.default.accent};
      }

      &[data-garden-selected='true'] {
        background-color: ${theme.formats.medium.background};
        border-color: ${theme.formats.medium.accent};

        &&:hover {
          color: ${theme.formats.medium.color};
          background-color: ${theme.formats.accent1.background};
          border-color: ${theme.formats.default.accent};
        }
      }
    `}
  `,
  'grid.row': css`
    ${commonOverrides}
    ${({ pad }) => (pad ? 'padding: 2em 0' : '')};
  `,
  'grid.col': css`
    ${commonOverrides}
    ${({ alignSelf }) => `
    align-self: ${alignSelf || 'center'};
  `};
    ${({ nowrap }) => (nowrap ? 'white-space: nowrap;' : '')};
    ${({ align }) => (align ? `text-align: ${align}` : '')};
    ${({ theme, alignMobile }) => (alignMobile ? `
      @media (max-width: ${theme.breakpoints.sm}) {
        text-align: ${alignMobile};
      }
    ` : '')};
  `,
  'modals.modal': css`
    width: 70%;
    height: 80%;
    max-height: 762px;
    @media (max-width: ${({ theme }) => theme.breakpoints.calc(theme.breakpoints.md, 'max')}) {
      width: 100%;
      height: 100%;
      min-height: 100%;
      left: 0;
    }
  `,
  'modals.header': css`
    text-transform: uppercase;
  `,
  'modals.backdrop': css`
    font-family: inherit;
  `,
  'modals.close': css`
    ${({ theme }) => `
      background: ${theme.colors.dark};
      color: ${theme.colors.light};
    `}
  `,
}
