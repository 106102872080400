import React, { useEffect, useMemo, useState } from 'react'
// TODO: explore importing Dropdown from @agnostack/components-atoms
import { Field, Dropdown, Select } from '@zendeskgarden/react-dropdowns'

import {
  isTrue,
  compareString,
  ensureArray,
  ensureObject,
  objectNotEmpty,
} from '@agnostack/lib-core'

import {
  StyledMenu,
  StyledItem,
  SelectedItem,
  Placeholder,
} from './ChildrenDropdown.style'

const ChildrenDropdown = ({ placeholder, data, selected, autoSelect = true, onSelect = () => {} }) => {
  const [selectedItem, setSelectedItem] = useState(undefined)

  const items = useMemo(() => ([
    ...placeholder ? [{
      label: placeholder,
      value: '',
    }] : [],
    ...data.sort(({ label: label1 }, { label: label2 }) => compareString(label1, label2))
  ]), [placeholder, data])

  useEffect(() => {
    if (isTrue(autoSelect)) {
      setSelectedItem(selected || ensureArray(items)[1])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelect, selected, items?.length])

  useEffect(() => {
    if (objectNotEmpty(selectedItem)) {
      onSelect(selectedItem.value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  return (
    <Dropdown
      selectedItem={selectedItem}
      downshiftProps={{ itemToString: (item) => item && item.label }}
      placeholder={placeholder}
      onSelect={setSelectedItem}
    >
      <Field>
        <Select>
          <SelectedItem item={selectedItem} />
          {(placeholder && !selectedItem?.value) && (
            <Placeholder>
              {placeholder}
            </Placeholder>
          )}
        </Select>
      </Field>
      <StyledMenu isBare>
        {ensureArray(items).map((item) => {
          const { label, value } = ensureObject(item)

          return (
            <StyledItem key={value} value={item}>
              {label}
            </StyledItem>
          )
        })}
      </StyledMenu>
    </Dropdown>
  )
}

export default ChildrenDropdown
