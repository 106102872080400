import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { window } from 'browser-monads-ts'

import { ensureObject, objectNotEmpty } from '@agnostack/lib-core'

const getDimensions = (container) => {
  const { innerWidth: width, innerHeight: height } = ensureObject(container)

  return {
    width,
    height,
  }
}

export const useDimensions = (debounce = 100, container = window) => {
  const [windowDimensions, _setWindowDimensions] = useState(
    getDimensions(container)
  )

  // NOTE delay the address callback to allow for simultantious changes to accumulate
  const setWindowDimensions = useDebouncedCallback((updatedDimensions) => {
    _setWindowDimensions(updatedDimensions)
  }, debounce, { leading: true })

  const resizeDimensions = ({ width, height }) => {
    const resizeWidth = width || windowDimensions?.width
    const resizeHeight = height || windowDimensions?.height

    if (resizeWidth && resizeHeight) {
      if (container.resizeTo) {
        container.resizeTo(resizeWidth, resizeHeight)
      }

      if (container.focus) {
        container.focus()
      }
    }
  }

  useEffect(() => {
    // TODO: debounce??
    const handleResize = () => {
      if (container) {
        const dimensions = getDimensions(container)
        if (objectNotEmpty(dimensions)) {
          setWindowDimensions(dimensions)
        }
      }
    }

    container.addEventListener('resize', handleResize)

    return () => (
      container.removeEventListener('resize', handleResize)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [windowDimensions, resizeDimensions]
}
