/* eslint-disable eqeqeq */
import { stringNotEmpty } from '@agnostack/lib-core'
import { window, document, exists } from 'browser-monads-ts'

export const hasWindow = exists(window)
export const hasDocument = exists(document)
export const hasMediaQuery = (hasWindow && window.matchMedia)
export const isBrowser = (hasWindow && hasDocument)
export const hasDOM = (isBrowser && (document.createElement != undefined))
export const hasBody = (hasDocument && (document.body != undefined))
export const hasQuerySelector = (hasDOM && document.querySelector)

export const scrollTo = (hash) => {
  if (hasQuerySelector && stringNotEmpty(hash) && document.querySelector(hash)) {
    document.querySelector(hash).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}
