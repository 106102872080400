import React from 'react'
import { navigate } from 'gatsby'

import {
  snakecase,
  toFormatted,
  getDateTimeFromString,
  removeLeadingTrailingSlash,
  DATE_FORMAT_MED,
} from '@agnostack/lib-core'

import {
  Screenshot,
  ScreenshotDate,
  ScreenshotTitle,
  ScreenshotImage,
} from './ScreenshotButton.style'

const ScreenshotButton = ({ title, path, date }) => {
  const screenShot = snakecase(removeLeadingTrailingSlash(path))
  const timestamp = getDateTimeFromString({ value: date, format: DATE_FORMAT_MED })
  return !path ? null : (
    <Screenshot title={title} onClick={() => navigate(path)}>
      <ScreenshotTitle>{title}</ScreenshotTitle>
      {timestamp && <ScreenshotDate>{toFormatted({ value: timestamp, format: 'MMM d, yyyy' })}</ScreenshotDate>}
      <ScreenshotImage
        alt={title}
        src={`/images/screenshots/${screenShot}.png`}
      />
    </Screenshot>
  )
}

export default ScreenshotButton
