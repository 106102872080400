import {
  toUnixString,
  ensureObject,
  ensureArray,
  stringEmpty,
  stringNotEmpty,
  stringNotEmptyOnly,
  stringEmptyOnly,
  objectNotEmpty,
  objectEmpty,
  cleanObject,
  templateReplace,
} from '@agnostack/lib-core'

import { ensureName, splitName } from './display'
import { getInstallationApplication } from './providers'
import { ORDER_TYPE, PRODUCT_LINE_ITEM_TYPES } from './constants'

const ORDER_TYPES = Object.values(ORDER_TYPE)

const formatEntityValues = (values, delimiter = ', ') => (
  [...new Set(ensureArray(values))].join(delimiter)
)

const conformRelateableData = (relateable) => {
  let type
  let relatedKey

  if (ORDER_TYPES.includes(relateable?.type)) {
    type = ORDER_TYPE.ORDER
    relatedKey = `${type}Items`
  } else if (['cart', 'cart_draft'].includes(relateable?.type)) {
    type = 'cart'
    relatedKey = `${type}Items`
  }

  const {
    relationships: {
      customer: {
        data: relatedCustomerData,
      } = {},
      items: {
        data: relatedItemsData,
      } = {},
    } = {},
  } = ensureObject(relateable)

  return {
    ...relatedKey && {
      type,
      relatedKey,
      relatedItemsData,
      relatedCustomerData,
    },
  }
}

const conformRelatedReplacements = (relatedItemsData, delimiter) => {
  const { relatedItems, items } = ensureArray(relatedItemsData).reduce((_replacements, relatedItem) => {
    const {
      relatedItems: _relatedItems,
      items: _items,
    } = _replacements

    const {
      ids: _ids,
      skus: _skus,
      names: _names,
      vendors: _vendors,
    } = ensureObject(_items)

    const {
      id,
      type,
      name,
      sku,
      meta: {
        product: {
          data: productData,
        } = {},
      } = {},
    } = ensureObject(relatedItem)

    if (!PRODUCT_LINE_ITEM_TYPES.includes(type)) {
      return _replacements
    }

    const vendor = ensureArray(productData).find(({ identifier }) => (
      identifier === 'vendor'
    ))?.value

    const hasID = stringNotEmpty(id)
    const hasSKU = stringNotEmpty(sku)
    const hasName = stringNotEmpty(name)
    const hasVendor = stringNotEmpty(vendor)

    if (!hasID && !hasSKU && !hasName && !hasVendor) {
      return _replacements
    }

    return {
      relatedItems: [
        ..._relatedItems,
        {
          ...hasID && { id },
          ...hasSKU && { sku },
          ...hasName && { name },
          ...hasVendor && { vendor },
        }
      ],
      items: {
        ..._items,
        ...hasID && {
          ids: [
            ..._ids,
            id
          ],
        },
        ...hasSKU && {
          skus: [
            ..._skus,
            sku
          ],
        },
        ...hasName && {
          names: [
            ..._names,
            name
          ],
        },
        ...hasVendor && {
          vendors: [
            ..._vendors,
            vendor
          ],
        },
      },
    }
  }, {
    relatedItems: [],
    items: {
      ids: [],
      skus: [],
      names: [],
      vendors: [],
    },
  })

  return {
    relatedItems,
    items: Object.entries(items).reduce((_items, [entity, values]) => {
      const entityValue = formatEntityValues(values, delimiter)

      return {
        ..._items,
        ...stringNotEmpty(entityValue) && {
          [entity]: entityValue,
        },
      }
    }, {}),
  }
}

const conformPersonReplacements = (person, type) => {
  const {
    id,
    email,
    signature,
    externalId,
    url: _url,
    avatarUrl: url = _url,
    first_name: firstName,
    last_name: lastName,
    name = ensureName({ firstName, lastName }),
    entity_id: _externalId = externalId, // NOTE: this is here just in case
    meta: {
      entity_id = _externalId,
    } = {},
  } = ensureObject(person)

  const {
    firstName: splitFirstName,
    lastName: splitLastName,
  } = splitName(name)

  const first_name = firstName || splitFirstName
  const last_name = lastName || splitLastName

  const customerReplacements = cleanObject({
    id,
    url,
    name,
    email,
    first_name,
    last_name,
    signature,
    ...(stringNotEmpty(entity_id) && (id !== entity_id)) && {
      entity_id,
    },
  }, false, stringEmptyOnly)

  if (objectEmpty(customerReplacements) || stringEmpty(type)) {
    return undefined
  }

  return {
    [type]: customerReplacements,
  }
}

export const conformCustomerReplacements = (customer) => (
  conformPersonReplacements(customer, 'customer')
)

export const conformAgentReplacements = (agent) => (
  conformPersonReplacements(agent, 'agent')
)

export const conformOrderableReplacements = (orderable, delimiter) => {
  const {
    type,
    relatedKey,
    relatedItemsData,
    relatedCustomerData,
  } = conformRelateableData(orderable)

  const {
    items,
    relatedItems,
  } = conformRelatedReplacements(relatedItemsData, delimiter)

  const {
    id,
    meta: {
      entity_id,
    } = {},
  } = ensureObject(orderable)

  return {
    ...stringNotEmpty(relatedKey) && {
      [relatedKey]: relatedItems,
      [type]: {
        ...orderable,
        ...(stringNotEmpty(entity_id) && (id !== entity_id)) && {
          entity_id,
        },
      },
      [`${type}_id`]: orderable.id,
    },
    ...objectNotEmpty(items) && { items },
    ...conformPersonReplacements(relatedCustomerData),
  }
}

export const conformProductReplacements = (product) => {
  const {
    id,
    sku,
    url,
    name,
    data,
    image,
    replacements, // TODO: add replacements to other types??
    entity_id: _entityId, // TODO!!!!: this should be inside meta
    meta: {
      entity_id = _entityId,
      variation: {
        modifiers,
        ...variation
      } = {},
      ...meta
    } = {},
  } = ensureObject(product)

  const modifiersById = ensureArray(modifiers).reduce((_modifiersById, productModifier) => ({
    ..._modifiersById,
    ...(stringNotEmpty(productModifier?.name) && stringNotEmpty(productModifier?.id)) && {
      [productModifier.name]: {
        id: productModifier.id,
        modifier: productModifier,
      },
    },
  }), {})

  const attributes = ensureArray(data).reduce((_attributes, productAttribute) => ({
    ..._attributes,
    ...(stringNotEmpty(productAttribute?.identifier) && stringNotEmptyOnly(productAttribute?.value)) && {
      [productAttribute.identifier]: productAttribute.value,
    },
  }), {})

  if (stringEmpty(id)) {
    return undefined
  }

  const cleanedReplacements = cleanObject(replacements, false, stringEmptyOnly)
  const productReplacements = cleanObject({
    id,
    sku,
    url,
    name,
    image,
    ...objectNotEmpty(modifiersById) && {
      meta: {
        ...meta,
        variation: {
          ...variation, // TODO: should this call conformProduct??
          modifiers: modifiersById,
        },
      },
    },
    ...objectNotEmpty(attributes) && { attributes },
    ...(stringNotEmpty(entity_id) && (id !== entity_id)) && {
      entity_id,
    },
  }, false, stringEmptyOnly)

  if (objectEmpty(productReplacements)) {
    return undefined
  }

  return {
    product: {
      ...productReplacements,
      ...cleanedReplacements,
    },
  }
}

export const conformItemReplacements = (_item) => {
  const {
    id,
    type,
    entity_id: _externalId, // NOTE: this is here just in case
    meta: {
      product,
      timestamps,
      entity_id = _externalId,
      ...meta
    } = {},
    ...item // TODO: further trim this conform down???
  } = ensureObject(_item)

  return {
    ...conformProductReplacements(product),
    ...stringNotEmpty(id) && cleanObject({
      item: {
        id,
        ...(stringNotEmpty(entity_id) && (id !== entity_id)) && {
          entity_id,
        },
        ...item, // TODO: further trim this conform down???
        meta: {
          entity_id,
          ...meta,
          ...conformProductReplacements(product),
        },
      },
    }, false, stringEmptyOnly),
  }
}

export const conformTicketReplacements = (ticket, delimiter) => {
  const {
    id,
    url,
    tags,
    status,
    subject,
    updatedAt,
    createdAt,
    created_at = createdAt,
    updated_at = updatedAt,
    description,
    collaborators,
    externalId,
    followers,
    requester,
    entity_id: _externalId = externalId, // NOTE: this is here just in case
    meta: {
      entity_id = _externalId,
    } = {},
    assignee: {
      group,
      ...assignee
    } = {},
  } = ensureObject(ticket)

  const ticketReplacements = cleanObject({
    id,
    url,
    group,
    status,
    subject,
    description,
    tags: formatEntityValues(tags, delimiter),
    followers: formatEntityValues(followers, delimiter),
    collaborators: formatEntityValues(collaborators, delimiter),
    ...conformPersonReplacements(requester, 'requester'),
    ...conformPersonReplacements(assignee, 'assignee'),
    ...stringNotEmpty(created_at) && { created_at: toUnixString(created_at) },
    ...stringNotEmpty(updated_at) && { updated_at: toUnixString(updated_at) },
    ...(stringNotEmpty(entity_id) && (id !== entity_id)) && {
      entity_id,
    },
  }, false, stringEmptyOnly)

  if (objectEmpty(ticketReplacements)) {
    return undefined
  }

  return {
    ticket: ticketReplacements,
  }
}

export const conformApplicationReplacements = (installation, providerSetId) => {
  const applicationReplacements = cleanObject(
    getInstallationApplication(installation, providerSetId),
    false,
    stringEmptyOnly
  )

  if (objectEmpty(applicationReplacements)) {
    return undefined
  }

  return {
    application: applicationReplacements,
  }
}

export const conformReplacements = ({
  cart,
  item,
  agent,
  order,
  ticket,
  product,
  customer,
  delimiter,
  installation,
  providerSetId,
  ...data
} = {}) => {
  const orderableReplacements = conformOrderableReplacements(cart || order, delimiter)

  return {
    ...data,
    ...conformApplicationReplacements(installation, providerSetId),
    ...conformItemReplacements(item),
    ...conformAgentReplacements(agent),
    ...conformTicketReplacements(ticket, delimiter),
    ...conformProductReplacements(product),
    ...orderableReplacements,
    ...conformCustomerReplacements(customer, orderableReplacements?.customer),
  }
}

// TODO: change default message to '{{application.platform}} via {{application.title}} by agent: {{agent.id}}'
export const getAuditMessage = (replacements, template = '{{application.platform}} via {{application.name}} by agent: {{agent.id}}') => (
  templateReplace(template, replacements)
)
