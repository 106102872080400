import React, { useMemo, useState } from 'react'
import { Col } from '@zendeskgarden/react-grid'

import {
  getData,
  lowercase,
  ensureArray,
  arrayNotEmpty,
  ensureObject,
} from '@agnostack/lib-core'
import { useAsyncMemo } from '@agnostack/lib-utils-react'

import { CheckBadgeIcon, PlusIcon } from '@agnostack/components-icons'

import Heading from '../Heading'
import {
  PricingTier,
  PricingCost,
  PricingWrapper,
  PricingFeature,
  PricingPerMonth,
  PricingPerAgent,
  PricingNote,
  FeatureNote,
  FeatureBody,
  MinimumAgents,
  StyledGrid,
  StyledRow,
  IconWrapper,
  LABELS,
} from './PricingTiers.style'

const LABEL_ICONS = {
  [LABELS.UPDATED]: CheckBadgeIcon,
  [LABELS.NEW]: CheckBadgeIcon,
  [LABELS.ADD_ON]: PlusIcon,
}

// TODO: consolidate with other PricingTiers component
const PricingTiers = ({
  tiers: _tiers,
  selectedListing: selectedListingProp,
  selectedPlan: selectedPlanProp,
  selectedTier: selectedTierProp,
  onSelect,
  ...renderProps
}) => {
  const [_selectedTier, setSelectedTier] = useState(selectedTierProp)

  const { tiers, lg } = useAsyncMemo(async () => {
    const __tiers = _tiers ?? await getData('pricingData')

    return {
      ...arrayNotEmpty(__tiers) && {
        tiers: __tiers,
        lg: Math.max(__tiers.length / 12, 3),
      },
    }
  }, [_tiers], {})

  const selectedPlan = useMemo(() => (lowercase(
    selectedPlanProp || ensureObject(selectedTierProp || _selectedTier).plan
  )), [selectedPlanProp, selectedTierProp, _selectedTier])

  const handleSelect = (tier) => {
    if (onSelect) {
      onSelect(tier)
    } else {
      setSelectedTier(tier)
    }
  }

  return (
    <StyledGrid {...renderProps}>
      <StyledRow>
        {ensureArray(tiers).map((pricingTier, tierIndex) => {
          const {
            plan,
            price_per_agent,
            minimum_agents,
            features,
          } = pricingTier

          return (
            <PricingTier
              key={`price-tier-${plan}-${tierIndex}`}
              align="center"
              alignSelf="start"
              lg={lg}
              selected={selectedPlan === lowercase(plan)}
              onClick={() => handleSelect(pricingTier)}
            >
              <Heading tag="3">{plan}</Heading>
              <PricingWrapper>
                <PricingCost>
                  {`$${price_per_agent}`}
                  <PricingPerMonth>/month</PricingPerMonth>
                </PricingCost>
                <PricingPerAgent>price per agent</PricingPerAgent>
                {minimum_agents && (
                  <MinimumAgents>
                    (billing minimum of $
                    {price_per_agent * minimum_agents}
                    /mo)
                  </MinimumAgents>
                )}
              </PricingWrapper>
              <div>
                {features.map(({ feature, labels, superscript, ...featureProps }, featureIndex) => {
                  const icons = ensureArray(labels).reduce((_icons, label) => {
                    const Icon = LABEL_ICONS[label]
                    return [
                      ..._icons,
                      ...Icon ? [(
                        <IconWrapper key={`price-tier-${plan}-${tierIndex}-feature-icon-${featureIndex}`}>
                          <Icon />
                        </IconWrapper>
                      )] : []
                    ]
                  }, [])

                  return (
                    <PricingFeature
                      key={`price-tier-${plan}-${tierIndex}-feature-${featureIndex}`}
                      {...featureProps}
                    >
                      <FeatureBody
                        icons={icons}
                        labels={labels}
                      >
                        {icons}
                        {feature}
                        {superscript && (
                          <sup>{superscript}</sup>
                        )}
                      </FeatureBody>
                    </PricingFeature>
                  )
                })}
              </div>
            </PricingTier>
          )
        })}
      </StyledRow>
      <StyledRow>
        <Col align="center">
          <FeatureNote>
            <IconWrapper>
              <CheckBadgeIcon />
            </IconWrapper>
            <b>NOW INTRODUCING - NEW OR UPDATED FEATURE</b>
          </FeatureNote>
          <FeatureNote>
            <IconWrapper>
              <PlusIcon />
            </IconWrapper>
            Included in plan, also available as Add-On to lower tiers.
          </FeatureNote>
          <FeatureNote>
            <sup>* </sup>
            Functionality/availability differs based on your configured Providers.
          </FeatureNote>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col align="center">
          <PricingNote>
            <i>NOTE:</i>
            <span> for businesses with over 100 agents or more than 10 storefronts, please contact </span>
            <a target="_blank" rel="noopener noreferrer" href="mailto:sales@agnostack.com">sales@agnostack.com</a>
            <span> for additional pricing details.</span>
          </PricingNote>
        </Col>
      </StyledRow>
    </StyledGrid>
  )
}

export default PricingTiers
