import styled from 'styled-components'
import { Link } from 'gatsby'

import { ChevronButton } from '@zendeskgarden/react-buttons'

import Heading from '../Heading'

export const StyledHeader = styled.div`
  border-style: none;
  background: none;
  text-align: left;
  cursor: pointer;
`

// TODO: revamp using, partiallyActive: true and activeClassName: 'active' (see partner_category.js)
export const StyledLink = styled(Link).attrs(({ isSelected }) => ({
  getProps: ({ isPartiallyCurrent }) => {
    const style = (isSelected || isPartiallyCurrent)
      ? {
        fontWeight: '600',
      } : {
        display: 'block',
        marginRight: 12,
      }
    return {
      style,
      // isCurrent: isPartiallyCurrent
    }
  },
}))``

export const SubCategoryHeader = styled(Heading).attrs({
  tag: '2',
  size: 'default',
})``

export const CategoryPage = styled(Heading).attrs({
  tag: '3',
  size: 'default',
})``

export const AccordionButton = styled.button`
  width: 100%;
  padding: unset;
  margin: 4px 0;
  border-style: none;
  background: none;
  text-align: left;
  font-weight: inherit;
  ${({ isChild }) => isChild && `
    &::before {
      content: "- ";
    }
  `}
  && {
    outline: none;
    cursor: pointer;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.accent1};
  }
`

export const AccordionSubcategory = styled.div`
  padding-bottom: 10px;
`

export const AccordionBody = styled.div`
  ${({ hasMultiple }) => hasMultiple && `
    padding-left: 18px;
  `}
`

export const AccordionHeading = styled(Heading).attrs(() => ({
  tag: '2',
  size: 'MD',
}))`
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  border-bottom: ${({ theme }) => theme.colors.tertiary} 1px solid;
`

export const Chevron = styled(ChevronButton)`
  border-style: none;
  background: none;
  background-color: transparent!important;
  box-shadow: unset!important;
  width: 20px;
  padding: 0;
  &&:hover {
    box-shadow: unset!important;
  }
`
