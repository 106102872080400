import React, { useMemo, useState } from 'react'

import { ensureArray, ensureObject, objectNotEmpty } from '@agnostack/lib-core'

import { RenderMarkdown } from '../../atoms'
import ChildrenDropdown from './ChildrenDropdown'

// NOTE: this is typically used to render a dropdown w/ child nodes in markdown
const ChildrenComponent = ({
  edges,
  overrides,
  component: Component = ChildrenDropdown,
  wrapper: Wrapper = 'div',
  ...renderProps
}) => {
  const [selectedId, setSelectedId] = useState()

  const { node } = useMemo(() => (
    ensureObject(ensureArray(edges).find(({
      node: {
        id,
      } = {},
    }) => (id === selectedId)))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [selectedId])

  const data = useMemo(() => (
    ensureArray(edges).reduce((_data, {
      node: {
        id: value,
        frontmatter: {
          title: label,
        } = {},
      } = {},
    }) => ([
      ..._data,
      {
        label,
        value,
      }
    ]), [])
  ), [edges])

  return (
    <div>
      <Component
        data={data}
        onSelect={setSelectedId}
        {...renderProps}
      />
      {(objectNotEmpty(node)) && (
        <Wrapper>
          <br />
          <RenderMarkdown
            node={node}
            overrides={overrides}
          />
        </Wrapper>
      )}
    </div>
  )
}
export default ChildrenComponent
