import React, { useReducer } from 'react'
import storage from 'local-storage-fallback'

import { stringNotEmpty, ensureObject, ensureDateTime } from '@agnostack/lib-core'

import GlobalStyles from '../styles/global'
import { GLOBAL_PARAMS, LISTING_NAMES } from './constants'

export const GlobalState = React.createContext()
export const GlobalDispatch = React.createContext()

export const GLOBAL_ACTIONS = {
  SET: 'SET',
  ACTIVE_LISTING: 'ACTIVE_LISTING',
  ACTIVE_FORMAT: 'ACTIVE_FORMAT',
  DISPATCH_MODAL: 'DISPATCH_MODAL',
}

export const MODAL_TYPES = {
  IFRAME: 'iframe',
  DEFAULT: 'modal',
}

const initialState = {
  activeFormat: 'medium',
  slidable: true,
}

const defaultReducer = (_state, _action) => {
  const { type, ...action } = ensureObject(_action)

  switch (type) {
    case GLOBAL_ACTIONS.ACTIVE_FORMAT: {
      const { activeFormat } = action
      return {
        ..._state,
        activeFormat,
      }
    }

    case GLOBAL_ACTIONS.DISPATCH_MODAL: {
      const { id, title, visible } = action
      const { modalsState = {} } = _state
      const { [id]: modalState } = modalsState

      const storageKey = `modal-${id}-viewed`
      let previousTimestamp
      let currentTimestamp

      try {
        previousTimestamp = storage.getItem(storageKey)
        currentTimestamp = ensureDateTime()
        if (visible) {
          storage.setItem(storageKey, currentTimestamp)
        }
      } catch (info) {
        console.info('Ignoring storage error', info)
      }

      return {
        ..._state,
        slidable: !visible,
        modalsState: {
          ...modalsState,
          [id]: {
            ...modalState,
            visible,
            title,
            previousTimestamp,
            currentTimestamp,
          },
        },
      }
    }

    case GLOBAL_ACTIONS.ACTIVE_LISTING: {
      const { [GLOBAL_PARAMS.LISTING]: activeListingName } = _state
      const {
        payload: {
          [GLOBAL_PARAMS.LISTING]: listingName,
        } = {},
      } = action

      if (stringNotEmpty(listingName) && (!LISTING_NAMES.includes(listingName) || (activeListingName === listingName))) {
        return _state
      }

      return {
        ..._state,
        [GLOBAL_PARAMS.LISTING]: listingName,
      }
    }

    case GLOBAL_ACTIONS.SET: {
      const { payload } = action
      return {
        ..._state,
        ...payload,
      }
    }

    default:
      throw new Error('Bad Reducer Action Type', action.type)
  }
}

export const GlobalContext = ({ children, ...initialProps }) => {
  const [state, dispatch] = useReducer(defaultReducer, {
    ...initialState,
    ...initialProps,
  })

  return (
    <>
      <GlobalStyles />
      <GlobalState.Provider value={state}>
        <GlobalDispatch.Provider value={dispatch}>
          {children}
        </GlobalDispatch.Provider>
      </GlobalState.Provider>
    </>
  )
}
